<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header, Footer
  }
}
</script>

<style>
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
@import '@riophae/vue-treeselect/dist/vue-treeselect.css';
</style>

<style>
body {
  background-color: #e4e5e6 !important;
}
.button-container {

}
.top-button {
  margin: 0.2rem;
  background-color: rgb(134, 134, 134) !important;
  border-color: rgb(134, 134, 134) !important;
  color: black !important;
}
.top-button:disabled {
  background-color: rgb(168, 168, 168) !important;
  border-color: rgb(168, 168, 168) !important;
}
.app-table {
  height: inherit !important;
}
.route-container {
  height: calc(100vh - 110px); /* ViewHeight minus header and footer*/
  display: flex;
  flex-direction: column;
}
.cog-via-css {
  display: block;
  width: 16px;
  height: 16px;
  background: transparent url("@/assets/cog-solid.svg") center/contain no-repeat;
}
</style>
