<template>
  <div class="route-container">
    <!-- <treeselect 
      class="my-2"
      v-model="selectedProjects" 
      placeholder="Auswahl Kunde/Projekt..."
      :options="clientProjectList_treeselect"
      :loadOptions="loadOptions"
      :multiple="true"
      :disable-branch-nodes="true"
      style="width:500px"
      :limit="2"
      @close="loadRowData()"
    /> -->
    <!-- Table -->
    <ag-grid-vue 
      class="app-table ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="single"
      @grid-ready="onGridReady"
      :sideBar="sideBar"
      :icons="icons"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import ControllingService from "../services/ControllingService";
import EventBus from '@/services/EventBus.js';

import { AgGridVue } from "ag-grid-vue";
import Treeselect from '@riophae/vue-treeselect';

import CurrencyCellRenderer from "./CellRenderer/CurrencyCellRenderer";
import DateCellRenderer from "./CellRenderer/DateCellRenderer";

import ConfigurationToolPanel from "./ConfigurationToolPanel";

export default {
  name: "ShopperCosts",
  components: {
    AgGridVue,
    ConfigurationToolPanel,
    currencyCellRenderer: CurrencyCellRenderer,
    dateCellRenderer: DateCellRenderer,
    Treeselect,
  },
  data() {
    return {
      clientList: null,
      clientProjectList_treeselect: null,
      columnDefs: null,
      gridApi: null,
      gridColumnApi: null,
      icons:{
        cog: "<i class='cog-via-css'/>"
      },
      isLoading: false,
      rowData: null,
      selectedProjects: [],
      selectedYear: null,
      sideBar: {
        toolPanels: [
          {
            id: 'configuration',
            labelDefault: 'Konfiguration',
            labelKey: 'configuration',
            iconKey: 'cog',
            toolPanel: 'ConfigurationToolPanel',
            toolPanelParams: {
              parentName: this.$options.name,
            },
          },
          {
            id: 'columns',
            labelDefault: 'Spalten',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: false,
              suppressColumnSelectAll: false,
              suppressColumnExpandAll: false,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filter',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: false,
              suppressFilterSearch: false,
            },
          },
        ],
      },
    }
  },
  beforeMount() {
    this.selectedYear = new Date().getFullYear();
    this.loadRowData();
    this.columnDefs = [
      { headerName: "Kunde", field: "ClientName", sortable: true, filter: true, width:200, resizable: true },
      { headerName: "Projekt", field: "ProjectName", sortable: true, filter: true, width:250, resizable: true },
      { headerName: "Unterprojekt", field: "SubProjectName", sortable: true, filter: true, width:150, resizable: true },
      { headerName: "Auftrag", field: "OrderName", sortable: true, filter: true, width:150, resizable: true },
      { headerName: "Kostenstelle", field: "FullCostCenterCode", sortable: true, filter: true, width:130, resizable: true },
      { headerName: "CL", field: "Consultant", sortable: true, filter: true, width:70, resizable: true },
      { headerName: "Etat TK Vergütung gesamt", field: "ExternalCostsMsmTk", sortable: true, filter: true, width:220, resizable: true, cellRenderer: 'currencyCellRenderer' },
      { headerName: "Checks gesamt", field: "PlannedSurveyInstanceNumber", sortable: true, filter: true, width:150, resizable: true, type: 'rightAligned' },
      { headerName: "Checks vergeben", field: "SettlementCount", sortable: true, filter: true, width:170, resizable: true, type: 'rightAligned' },
      { headerName: "Checks verbleiben", field: "SettlementsLeft", sortable: true, filter: true, width:170, resizable: true, type: 'rightAligned' },
      { headerName: "TK Vergütung ist", field: "OrderPayout", sortable: true, filter: true, width:180, resizable: true, cellRenderer: 'currencyCellRenderer' },
      { headerName: "TK Vergütung verbleibt", field: "ExternalCostsMsmTkLeft", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' }, 
      { headerName: "Durchschnittliche Vergütung ist", field: "OrderPayoutAverage", sortable: true, filter: true, width:250, resizable: true, cellRenderer: 'currencyCellRenderer' }, 
      { headerName: "Durchschnittliche Vergütung verbleibt", field: "OrderPayoutAverageLeft", sortable: true, filter: true, width:300, resizable: true, cellRenderer: 'currencyCellRenderer' }, 
      { headerName: "Tendenz", field: "OrderPayoutTendency", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' }, 
    ];
  },
  mounted() {
        EventBus.$on(`${this.$options.name}_newConfig`, params => {
      this.selectedYear = params.selectedYear;
      this.loadRowData();
    })
  },
  watch: {
    $route (to, from){
      if (this.$router.currentRoute.path == "/shopperCosts") {
        this.loadRowData();
      }
    }
  },
  methods: {
    loadOptions({callback}){
      this.isLoading = true;
      ControllingService.getClientWithProjectAndSubProject()
      .then((res) => {
        this.clientList = res.data;
        this.clientProjectList_treeselect = res.data.map(item => {
          return { 
            id: item.ClientId, 
            label: item.ClientName, 
            children: item?.Projects?.map(p => {
              return {id: p.ProjectId, label: p.ProjectName}
            }),
            isDisabled: (item?.Projects) ? false : true
          };
        });
      })
      .finally(() => {
        this.isLoading = false;
        callback();
      })
    },
    loadRowData() {
      if(this.gridApi) this.gridApi.showLoadingOverlay();

      ControllingService.getSubprojectAndOrder(null, null, null, null, this.selectedYear)
      .then(res => {
        this.rowData = [];
        if(res.data === '') return;
        res?.data?.forEach(subproject => {
          subproject?.Orders?.forEach(order => {
            this.rowData.push(order);
          })
        })
      })
      .finally(() => {
        if(this.gridApi) this.gridApi.hideOverlay();
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
  },
}
</script>

<style>

</style>