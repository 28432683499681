<template>
  <div class="route-container">
    <div class="table-controls">
      <b-button class="top-button" @click="toggleOrganizationEditPopup(false)" >Organisation erstellen</b-button>
      <b-button class="top-button" @click="toggleOrganizationEditPopup(true)" :disabled="!selectedOrganization">Organisation bearbeiten</b-button>
    </div>
    <!-- Table -->
    <ag-grid-vue 
      class="app-table ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      @grid-ready="onGridReady"
      rowSelection="single"
      :masterDetail="true"
      :keepDetailRowsCount="1"
      :groupDefaultExpanded="0"
      @row-clicked="onRowClicked"
      :detailCellRendererParams="detailCellRendererParams"
      :getRowHeight="getRowHeight"
    >
    </ag-grid-vue>
    <OrganizationEditPopup 
      v-if="showOrganizationEditPopup" 
      @close="closeOrganizationEditPopup(false)"
      @close-reload="closeOrganizationEditPopup(true)"
      :edit="edit"
      :selectedOrganization="selectedOrganization"
    ></OrganizationEditPopup>
  </div>
</template>

<script>
import EventBus from "../services/EventBus";
import ControllingService from "../services/ControllingService";

import { AgGridVue } from "ag-grid-vue";
import 'ag-grid-enterprise';

import OrganizationEditPopup from "./OrganizationEditPopup";

export default {
  name: "ConstCenterManagement",
  components: {
    AgGridVue,
    OrganizationEditPopup
  },
  data() {
    return {
      detailCellRendererParams: null,
      edit: false,
      getRowHeight: null,
      gridApi: null,
      gridColumnApi: null,
      rowData: null,
      selectedOrganization: null,
      showOrganizationEditPopup: false
    }
  },
  created() {
    this.getRowHeight = (params) => {
      if (params.node?.detail && params.data?.Orders) {
        var offset = 80;
        var allDetailRowHeight =
          (params.data.Orders.length <= 10 ? params.data.Orders.length : 10) *
          params.api.getSizesForCurrentTheme().rowHeight;
        var gridSizes = params.api.getSizesForCurrentTheme();
        return (
          allDetailRowHeight +
          ((gridSizes && gridSizes.headerHeight) || 0) +
          offset
        );
      }
    };
  },
  beforeMount() {
    this.loadRowData();
  },
  mounted() {
    this.detailCellRendererParams = {
      // level 2 grid options
      detailGridOptions: {
        columnDefs: [
          { headerName: 'Kostenstelle Block 2', field: 'ClientOrganizationCode' },
          { headerName: 'Kunde', field:'ClientName' },

        ],
        defaultColDef: {
          flex: 1,
        },
        rowSelection: 'single'
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.Clients);
      },
    };
  },
  computed: {
    columnDefs() {
      return [
        { headerName: "Organisation", field: "OrganizationName", sortable: true, filter: true, width:350, resizable: true },
        { headerName: "Kostenstelle Block 1", field: "OrganizationCode", sortable: true, filter: true, width:250, resizable: true },
        { headerName: "Anzahl an Kunden", field: "AssignedClientCount", sortable: true, filter: true, width:700, resizable: true },
      ];
    },
  },
  methods: {
    closeOrganizationEditPopup() {
      this.showOrganizationEditPopup = false;
      this.loadRowData();
    },
    loadRowData() {
      ControllingService.getOrganization()
      .then(res => {
        this.rowData = [];
        if(res.data === '') return;
        this.rowData = res.data;
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onRowClicked() {
      // collapse other rows on selectionchange
      this.gridApi.forEachNode(function (node) {
        if(node.selected === true) {
          node.expanded = !node.expanded;
        } else {
          node.expanded = false;
        }
      });
      this.gridApi.onGroupExpandedOrCollapsed();

      this.selectedOrganization = this.gridApi.getSelectedRows()[0];
    },
    toggleOrganizationEditPopup(edit) {
      this.showOrganizationEditPopup = !this.showOrganizationEditPopup;
      this.edit = edit;
    },
  },
}
</script>

<style scoped>
.table-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>