<template>
  <div v-if="this.params.data" style="width: fit-content;">
    <font-awesome-icon
      v-if="!this.params.value"
      class="mx-1"
      icon="fa-solid fa-pen"
      @click="showPopup"
      style="cursor:pointer;"
    />
    <font-awesome-icon
      v-else
      class="mx-1"
      icon="fa-solid fa-pen"
      style="color:gray;"
    />
  </div>
</template>

<script>
import EventBus from '../../services/EventBus';
export default {
  methods: {
    showPopup() {
      EventBus.$emit("showInvoiceEditPopup", this.params.data);
    }
  }
}
</script>