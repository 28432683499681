import axios from 'axios';

export default {
  baseURL: process.env.BASE_URL + 'api/controlling',

  base64Encode(data) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  },  

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: mimeString});
  },

  srcToFile(src, fileName, mimeType){
    return (fetch(src)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], fileName, {type:mimeType});})
    );
  },

  openDataURI(dataURI) {
    var file = this.dataURItoBlob(dataURI);
    var fileURL = URL.createObjectURL(file);
    console.log(fileURL);
    window.open(fileURL);
    setTimeout(() => {
      URL.revokeObjectURL(fileURL);
    }, 0);
  },

  async uploadFile(orderId, year, file) {
    const res = await this.base64Encode(file);
    const upload = {
      OrderId: orderId,
      Year: year,
      DataURI: res,
      Name: file.name
    }
    return await this.setUpload(upload);
  },

  //// axios requests ////
  async setUpload(upload) {
    return axios.post(
      'set-upload', 
      upload,
      {
        baseURL: this.baseURL
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  async getUpload(uploadId) {
    return axios.get(
      'get-upload',
      { 
        baseURL: this.baseURL,
        params: {
        "upload-id": uploadId
        },
      },
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  async deleteUpload(uploadId) {
    return axios.get(
      'delete-upload',
      { 
        baseURL: this.baseURL,
        params: {
        "upload-id": uploadId
        },
      },
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },
}