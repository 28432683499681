<template>
  <header class="app-header">
    <img
      class="header-logo mw-100 mh-100"
      src="../../public/msm_loop_logo_40px.png"
      @click="routerPush('/')"
    />
    <div class="header-navigation px-4">
      <span
        :class="'header-link ' + (currentRoute === '/management' ? 'header-link-active' : '')"
        v-if="hasPermission('Management')"
        @click="routerPush('/management')"
      >
        <span class="header-text-long">Geschäftsführung</span>
        <span class="header-text-short">GF</span>
      </span>
      <span
        :class="'header-link ' + (currentRoute === '/sales' ? 'header-link-active' : '')"
        v-if="hasPermission('Sales')"
        @click="routerPush('/sales')"
      >Vertrieb
      </span>
      <span
      :class="'header-link ' + (currentRoute === '/projectManagement' ? 'header-link-active' : '')"
        v-if="hasPermission('ProjectManagement')"
        @click="routerPush('/projectManagement')"
      >
        <span class="header-text-long">Projektmanagement</span>
        <span class="header-text-short">PM</span>
      </span>
      <span
      :class="'header-link ' + (currentRoute === '/qualityAssurance' ? 'header-link-active' : '')"
        v-if="hasPermission('QualityAssurance')"
        @click="routerPush('/qualityAssurance')"
      >
        <span class="header-text-long">Qualitätssicherung</span>
        <span class="header-text-short">QSS</span>
      </span>
      <span
      :class="'header-link ' + (currentRoute === '/field' ? 'header-link-active' : '')"
        v-if="hasPermission('Field')"
        @click="routerPush('/field')"
      >Feld
      </span>
      <span
      :class="'header-link ' + (currentRoute === '/shopperCosts' ? 'header-link-active' : '')"
        v-if="hasPermission('ShopperCosts')"
        @click="routerPush('/shopperCosts')"
      >
        <span class="header-text-long">Testkundenkosten</span>
        <span class="header-text-short">TKK</span>
      </span>
      <span
      :class="'header-link ' + (currentRoute === '/orderManagement' ? 'header-link-active' : '')"
        v-if="hasPermission('OrderManagement')"
        @click="routerPush('/orderManagement')"
      >
        <span class="header-text-long">Auftragsverwaltung</span>
        <span class="header-text-short">Auftrags-<br>verwaltung</span>
      </span>
      <span
      :class="'header-link ' + (currentRoute === '/invoiceManagement' ? 'header-link-active' : '')"
        v-if="hasPermission('InvoiceManagement')"
        @click="routerPush('/invoiceManagement')"
      >
        <span class="header-text-long">Rechnungsverwaltung</span>
        <span class="header-text-short">Rechnungs-<br>verwaltung</span>
      </span>
      <span
      :class="'header-link ' + (currentRoute === '/costCenterManagement' ? 'header-link-active' : '')"
        v-if="hasPermission('CostCenterManagement')"
        @click="routerPush('/costCenterManagement')"
      >
        <span class="header-text-long">Kostenstellenverwaltung</span>
        <span class="header-text-short">Kostenstellen-<br>verwaltung</span>
      </span>
      <span
      :class="'header-link ' + (currentRoute === '/archive' ? 'header-link-active' : '')"
        v-if="hasPermission('Archive')"
        @click="routerPush('/archive')"
      >Archiv
      </span>
      <div class="header-link" @click="logout()">Logout</div>
    </div>
  </header>
</template>

<script>
import AuthenticationService from "../services/AuthenticationService";

export default {
  data() {
    return {
      currentRoute: null
    };
  },
  watch: {
    $route (to, from){
        this.currentRoute = this.$router.currentRoute.path;
    }
  },
  mounted() {
    this.currentRoute = this.$router.currentRoute.path;
  },
  methods: {
    hasPermission(permission) {
      return AuthenticationService.hasPermission(permission);
    },
    logout() {
      AuthenticationService.logout();
    },
    routerPush(path) {
      if (this.$router.currentRoute.path != path) {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped>
.app-header {
  position: sticky;
  z-index: 100;
  top: 0%;
  width: 100%;
  height: 40px;
  background: #52688d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.5);
}
.header-logo {
  background: white;
  cursor: pointer;
  padding: 0.25rem 1rem;
}
.header-navigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header-link {
  text-align: center;
  margin: auto;
  line-height: 15px;
}
.header-link:hover {
  cursor: pointer;
  color: white;
}
.header-link-active {
  color: white;
}
.header-text-long {
  display: block;
}
.header-text-short {
  display: none;
}
@media screen and (max-width: 1400px) {
  .header-text-long {
    display: none;
  }
  .header-text-short {
    display: block;
  }
}
</style>