<template>
  <div class="m-2">
    <b-form-checkbox 
      v-model="all"
      :indeterminate="hasTrue && hasFalse"
      @change="emitEventAll()"
    >Alle</b-form-checkbox>
    <hr>
    <div v-for="(def, index) in detailColumnDefs" v-bind:key="index">
      <b-form-checkbox 
        v-model="def.hide"
        :value="false"
        :unchecked-value="true"
        @change="emitEvent(def.field, def.hide)"
      >{{ def.headerName }}</b-form-checkbox>
    </div>
  </div>
</template>

<script>
import EventBus from '@/services/EventBus.js'

export default {
  name: "DetailColumnToolPanel",
  data() {
    return {
      all: true,
      detailColumnDefs: null,
      hasFalse: null,
      hasTrue: null,
    }
  },
  mounted() {
    this.detailColumnDefs = [
      { headerName: "Auftrag", field: "OrderName", hide: false },
      { headerName: "Kostenstelle", field: "FullCostCenterCode", hide: false },
      { headerName: "CL", field: "Consultant", hide: false },
      { headerName: "Startdatum", field: "StartDate", hide: false },
      { headerName: "Enddatum", field: "EndDate", hide: false },
      { headerName: "Budget", field: "Budget", hide: false },
      { headerName: "Rückstellungen", field: "Reserve", hide: false },
      { headerName: "Budget abz. Rückstellungen", field: "BudgetMinusReserve", hide: false }, 
      { headerName: "G&G in €", field: "OverheadCosts", hide: false },
      { headerName: "G&G in %", field: "OverheadCharge", hide: false },
      { headerName: "Vertriebsaufschlag in €", field:"MarketingCosts", hide: false },
      { headerName: "Vertriebsaufschlag in %", field:"MarketingCharge", hide: false },
      { headerName: "Fremdkosten MSM", field: "ExternalCostsMsmTk", hide: false },
      { headerName: "Fremdkosten Agentur", field: "ExternalCostsAgency", hide: false },
      { headerName: "Fremdkosten BOLD", field: "ExternalCostsMdatiqs", hide: false },
      { headerName: "Fremdkosten BOLD 2€ pro Check", field: "SurveyInstancePriceMdatiqs", hide: false },
      { headerName: "Fremdkosten Sonstiges", field: "ExternalCostsOther", hide: false },
      { headerName: "DB1", field:"ContributionMargin", hide: false },
      { headerName: "Verfügbares Abteilungsbudget", field: "DepartmentBudget", hide: false },
      { headerName: "Budget Feld", field: "BudgetField", hide: false },
      { headerName: "Restbudget Feld", field: "RemainingBudgetField", hide: false },
      { headerName: "Budget PM", field: "BudgetProjectManagement", hide: false },
      { headerName: "Restbudget PM", field: "RemainingBudgetProjectManagement", hide: false },
      { headerName: "Budget QSS", field: "BudgetQualityAssurance", hide: false },
      { headerName: "Restbudget QSS", field: "RemainingBudgetQualityAssurance", hide: false },
      { headerName: "Verbleibendes Budget", field: "RemainingBudget", hide: false },
      { headerName: "Erfüllungsrate Checks", field: "SurveyInstanceCount", hide: false },
    ]
  },
  watch: {
    detailColumnDefs: {
      handler() {
        this.hasTrue = false;
        this.hasFalse = false;
        this.detailColumnDefs.forEach(e => {
          if(e.hide === false) { this.hasTrue = true; }
          else if(e.hide === true) { this.hasFalse = true; }
        });
        if(this.hasTrue && this.hasFalse) { 
          this.all = false;
        } else if(!this.hasTrue && this.hasFalse) {
          this.all = false;
        } else if(this.hasTrue && !this.hasFalse) {
          this.all = true;
        }
      },
      deep: true
    },
  },
  methods: {
    emitEvent(field, hide) {
      EventBus.$emit('DetailColumnHide', field, hide);
    },
    emitEventAll() {
      this.detailColumnDefs.forEach(e => {
        if(this.all === true) {
          e.hide = false;
        } else {
          e.hide = true;
        }
        EventBus.$emit('DetailColumnHideAll', !this.all);
      })
    }
  },
}
</script>

<style scoped>

</style>