<template>
  <div v-if="this.params.value" style="text-align:right">
    {{date}}
  </div>
</template>
<script>
export default {
  data() {
    return {
      date: null
    }
  },
  mounted() {
    this.date = new Date(Date.parse(this.params?.value)).toLocaleDateString();
  },
}
</script>