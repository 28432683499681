<template>
  <div v-if="this.params.value">
    <div v-if="this.params.value > 0">{{this.params.value}} Std.</div>
    <div v-else style="color: red;">{{this.params.value}} Std.</div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>