import moment from 'moment';
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import LocalizationsService from '@/services/LocalizationsService'
import EventBus from '@/services/EventBus.js';

Vue.use(VueI18n);

const messages = { };

export const i18n = new VueI18n({
  locale: '',
  fallbackLocale: 'en-GB',
  messages,
  silentTranslationWarn: true
});

export default i18n;

function setI18nLocalization(localizationId) {
  i18n.locale = localizationId;
  //axios.defaults.headers.common['Accept-Language'] = localizationId;
  document.querySelector('html').setAttribute('lang', localizationId);

  // Sets actual localization for localized date & time and number formats
  moment.locale(localizationId == 'deflt' ? 'en-GB' : localizationId);
  
  EventBus.$emit('LocalizationChanged', localizationId);

  return localizationId;
}

export function changeLocalization(localizationId) {
  // Fix 'de-at' to 'de-AT' etc.
  if (localizationId && localizationId.length === 5 && localizationId.includes('-')) {
    var textitem = _.split(localizationId, "-", 2);
    localizationId = textitem[0] + "-" + textitem[1].toUpperCase();
  }

  return LocalizationsService.getApplicationItemTexts(localizationId).then(
    response => {
      if (!_.isEmpty(response.data)) {
        i18n.setLocaleMessage(localizationId, response.data);
        return Promise.resolve(setI18nLocalization(localizationId));
      }
    }
  );
}
