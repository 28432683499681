<template>
  <div v-if="reminderLevel >= 0" style="text-align:left">
    <font-awesome-icon
      v-if="reminderLevel >= 1"
      class="mx-1"
      icon="fa-regular fa-square-check"
      style="cursor:pointer;"
      @click="updateReminderLevel(1)"
    />
    <font-awesome-icon
      v-else
      class="mx-1"
      icon="fa-regular fa-square"
      style="cursor:pointer;"
      @click="updateReminderLevel(1)"
    />
    <font-awesome-icon
      v-if="reminderLevel >= 2"
      class="mx-1"
      icon="fa-regular fa-square-check"
      style="cursor:pointer;"
      @click="updateReminderLevel(2)"
    />
    <font-awesome-icon
      v-else
      class="mx-1"
      icon="fa-regular fa-square"
      style="cursor:pointer;"
      @click="updateReminderLevel(2)"
    />
    <font-awesome-icon
      v-if="reminderLevel >= 3"
      class="mx-1"
      icon="fa-regular fa-square-check"
      style="cursor:pointer;"
      @click="updateReminderLevel(3)"
    />
    <font-awesome-icon
      v-else
      class="mx-1"
      icon="fa-regular fa-square"
      style="cursor:pointer;"
      @click="updateReminderLevel(3)"
    />
  </div>
</template>

<script>
import EventBus from '../../services/EventBus';
export default {
  data() {
    return {
      reminderLevel: null,
    }
  },
  mounted() {
    this.reminderLevel = this.params.value;
  },
  methods: {
    updateReminderLevel(newLevel) {
      EventBus.$emit("updateReminderLevel", this.params.data, newLevel);
    }
  },
}
</script>