<template>
  <div v-if="this.params.value" style="text-align:right">
    <div v-if="!isRed">{{this.params.value}} %</div>
    <div v-else style="color: red;">{{this.params.value}} %</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRed: false
    }
  },
  mounted() {
    if(this.params.value && this.params.value > 100) this.isRed = true;
  },
}
</script>
