<template>
  <div class="animated fadeIn">
    <h2>Imprint</h2>

    <h4 style="margin-top:1em"><b>BOLD</b> Solution GmbH</h4>

    <p style="margin-top:1em">An der Alten Ziegelei 34<br/>
    DE-48157 Münster<br/>
    <a href="mailto:contact@boldsolution.com" title="contact@boldsolution.com">contact@boldsolution.com</a></p>

    <p><strong>Managing Director:</strong> Ferdinand Hissmann, Christian Karrenbauer<br/>
    <strong>Register court:</strong> Amtsgericht Münster · <strong>Register Number:</strong> HRB 56 02<br/>
    <strong>Sales tax identification number according § 27 a VAT law:</strong> DE 205 640 029</p>

    <p><strong>Disclaimer:</strong><br/>
    Despite careful content control we assume no liability for the content of external links.<br/>
    For the content of linked pages their operators are responsible.</p>

    <h2 style="margin-top:1em">Impressum</h2>

    <h4 style="margin-top:1em"><b>BOLD</b> Solution GmbH</h4>

    <p style="margin-top:1em">An der Alten Ziegelei 34<br/>
    DE-48157 Münster<br/>
    <a href="mailto:contact@boldsolution.com" title="contact@boldsolution.com">contact@boldsolution.com</a></p>

    <p><strong>Vertretungsberechtigte Geschäftsführer:</strong> Ferdinand Hissmann, Christian Karrenbauer<br/>
    <strong>Registergericht:</strong> Amtsgericht Münster · <strong>Registernummer:</strong> HRB 56 02<br/>
    <strong>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</strong> DE 205 640 029</p>
    
    <p><strong>Haftungshinweis:</strong><br/>
    Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links.
    Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
  </div>
</template>

<script>
export default {
  name: 'Imprint'
}
</script>
