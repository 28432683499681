<template>
  <div class="m-1" style="display: flex; flex-direction: column; position: relative">
    <span class="mt-4">Wirtschaftsjahr: </span>
    <b-form-select v-model="selectedYear" :options="getYears()" style="width: 180px;"></b-form-select>

    <template v-if="params.parentName == 'Management' || params.parentName == 'InvoiceManagement'">
      <span class="mt-4 mx-2">Rechnungen filtern von: </span>
      <b-input type="date" style="width: 180px" v-model="invoiceDateStart"></b-input>
      <span class="mt-2 mx-2">bis: </span>
      <b-input type="date" style="width: 180px" v-model="invoiceDateEnd"></b-input>
    </template>

    <b-button class="mt-4 mx-2" @click="emitEvent()">Los!</b-button>
  </div>
</template>

<script>
import EventBus from '@/services/EventBus.js';

export default {
  name: "ConfigurationColumnToolPanel",
  data() {
    return {
      invoiceDateStart: null,
      invoiceDateEnd: null,
      selectedYear: null
    }
  },
  mounted() {
    this.selectedYear = new Date().getFullYear();
    if(this.params.parentName == "Management") {
      this.invoiceDateStart = new Date().getFullYear() + '-01-01';
      this.invoiceDateEnd = new Date().getFullYear() + '-12-31';
    }
  },
  methods: {
    getYears() {
      const years = [];
      const lastYear = new Date().getFullYear() + 1;
      const firstYear = lastYear - 5;
      for (let year = lastYear; year >= firstYear; year--) years.push(year);
      return years;
    },
    emitEvent() {
      var eventName = this.params.parentName + '_newConfig'
      EventBus.$emit(eventName, {invoiceDateStart: this.invoiceDateStart, invoiceDateEnd: this.invoiceDateEnd, selectedYear: this.selectedYear});
    },
  },
  watch: {

  },
}
</script>

<style scoped>
span {
  font-size: 12pt;
}
</style>