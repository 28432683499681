<template>
  <div v-if="this.params.value" style="text-align:right">
    <div v-if="!isNegative">{{number}} €</div>
    <div v-else style="color: red;">{{number}} €</div>
  </div>
  <!-- <div v-else style="text-align:right">
    0 €
  </div> -->
</template>
<script>
export default {
  data() {
    return {
      number: null,
      isNegative: false,
    }
  },
  mounted() {
    if(this.params.value) this.getFormattedValue();
  },
  methods: {
    getFormattedValue() {
      var indexOfPoint = this.params.value.toString().indexOf('.');
      var number;
      if(indexOfPoint != -1) {
        number = this.params.value.toString().substring(0, indexOfPoint);
      } else {
        number = this.params.value.toString();
      }
      if(number < 0) {
        this.isNegative = true;
        number = (number * (-1)).toString();
      }
      if (number.length > 3) {
        var mod = number.length % 3;
        var output = (mod > 0 ? (number.substring(0,mod)) : '');
        for (var i = 0 ; i < Math.floor(number.length / 3); i++) {
          if ((mod == 0) && (i == 0))
            output += number.substring(mod + 3 * i, mod + 3 * i + 3);
          else
            output += '.' + number.substring(mod + 3 * i, mod + 3 * i + 3);
        }
        this.number = output;
      }
      else {
        this.number = number;
      }
      // append decimals
      if(indexOfPoint != -1) {
        var decimals = this.params.value.toString().substring(indexOfPoint);
        this.number = this.number + ',' + decimals.substring(1,3);
        if(decimals.length < 3) {
          this.number = this.number + '0'
        }
      } else {
        this.number = this.number + ',00';
      }
      if(this.isNegative) {
        this.number = '-' + this.number;
      }
    }
  },
}
</script>