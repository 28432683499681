import axios from 'axios';

export default {
  baseURL: process.env.BASE_URL + 'api/users',

  getUserProfile() {
    return axios.get('profile',
      { 
        baseURL: this.baseURL
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
        return Promise.reject(error);
      });
  },

  // setUserProfile(settings) {
  //   return axios.put('profile', settings,
  //     { 
  //       baseURL: this.baseURL,
  //     })
  //     .then(function (response) {
  //       return response;
  //     });
  // }
}