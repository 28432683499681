<template>
  <div class="route-container">
    <ag-grid-vue 
      class="app-table ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="single"
      @grid-ready="onGridReady"
      :sideBar="sideBar"
      :icons="icons"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import ControllingService from "../services/ControllingService";
import EventBus from '@/services/EventBus.js';

import { AgGridVue } from "ag-grid-vue";

import CurrencyCellRenderer from "./CellRenderer/CurrencyCellRenderer";
import DateCellRenderer from "./CellRenderer/DateCellRenderer";
import HourCellRenderer from "./CellRenderer/HourCellRenderer";
import SurveyInstanceCountCellRenderer from "./CellRenderer/SurveyInstanceCountCellRenderer";

import ConfigurationToolPanel from "./ConfigurationToolPanel";

export default {
  name: "QualityAssurance",
  components: {
    AgGridVue,
    ConfigurationToolPanel,
    currencyCellRenderer: CurrencyCellRenderer,
    dateCellRenderer: DateCellRenderer,
    hourCellRenderer: HourCellRenderer,
    surveyInstanceCountCellRenderer: SurveyInstanceCountCellRenderer,
  },
  data() {
    return {
      columnDefs: null,
      gridApi: null,
      gridColumnApi: null,
      icons:{
        cog: "<i class='cog-via-css'/>"
      },
      rowData: null,
      selectedYear: null,
      sideBar: {
        toolPanels: [
          {
            id: 'configuration',
            labelDefault: 'Konfiguration',
            labelKey: 'configuration',
            iconKey: 'cog',
            toolPanel: 'ConfigurationToolPanel',
            toolPanelParams: {
              parentName: this.$options.name,
            },
          },
          {
            id: 'columns',
            labelDefault: 'Spalten',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: false,
              suppressColumnSelectAll: false,
              suppressColumnExpandAll: false,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filter',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: false,
              suppressFilterSearch: false,
            },
          },
        ],
      },
    }
  },
  beforeMount() {
    this.selectedYear = new Date().getFullYear();
    this.loadRowData();
    this.columnDefs = [
      { headerName: "Kunde", field: "ClientName", sortable: true, filter: true, width:200, resizable: true },
      { headerName: "Projekt", field: "ProjectName", sortable: true, filter: true, width:250, resizable: true },
      { headerName: "Unterprojekt", field: "SubProjectName", sortable: true, filter: true, width:150, resizable: true },
      { headerName: "Auftrag", field: "OrderName", sortable: true, filter: true, width:150, resizable: true },
      { headerName: "Kostenstelle", field: "FullCostCenterCode", sortable: true, filter: true, width: 130, resizable: true },
      { headerName: "CL", field: "Consultant", sortable: true, filter: true, width:70, resizable: true },
      { headerName: "Startdatum", field: "StartDate", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'dateCellRenderer' },
      { headerName: "Enddatum", field: "EndDate", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'dateCellRenderer' },
      { headerName: "Budget QSS", field: "BudgetQualityAssurance", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' },
      { headerName: "Verbrauchtes Budget", field: "SalaryQualityAssurance.Salary", sortable: true, filter: true, width: 180, resizable: true, cellRenderer: 'currencyCellRenderer'},
      { headerName: "Reststunden", field: "SalaryQualityAssurance.RemainingHours", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'hourCellRenderer' },
      { headerName: "Erfüllungsrate Checks", field: "SurveyInstanceCount", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'surveyInstanceCountCellRenderer' },
    ];
  },
  mounted() {
        EventBus.$on(`${this.$options.name}_newConfig`, params => {
      this.selectedYear = params.selectedYear;
      this.loadRowData();
    })
  },
  watch: {
    $route (to, from){
      if (this.$router.currentRoute.path == "/qualityAssurance") {
        this.loadRowData();
      }
    }
  },
  methods: {
    loadRowData() {
      if(this.gridApi) this.gridApi.showLoadingOverlay();

      ControllingService.getSubprojectAndOrder(null, null, null, null, this.selectedYear)
      .then(res => {
        this.rowData = [];
        if(res.data === '') return;
        res?.data?.forEach(subproject => {
          subproject?.Orders?.forEach(order => {
            this.rowData.push(order);
          })
        })
      })
      .finally(() => {
        if(this.gridApi) this.gridApi.hideOverlay();
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
  },
}
</script>

<style>

</style>