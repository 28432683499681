<template>
  <div class="route-container">
    <div class="table-controls">
      <b-button class="top-button" @click="toggleInvoiceEditPopup()" :disabled="!selectedOrder || viewOpenInvoices">Rechnung erstellen</b-button>
      <b-form-checkbox class="ml-5" v-model="viewOpenInvoices" switch >
        <span v-if="!viewOpenInvoices">
          <b>Rechnungsübersicht</b> / "Offene Rechnungen"-Ansicht
        </span>
        <span v-else>
          Rechnungsübersicht / <b>"Offene Rechnungen"-Ansicht</b>
        </span>
      </b-form-checkbox>
    </div>
    <!-- Table -->
    <ag-grid-vue 
      v-show="!viewOpenInvoices"
      class="app-table ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      @grid-ready="onGridReady"
      rowSelection="single"
      :sideBar="sideBar"
      :masterDetail="true"
      :keepDetailRowsCount="1"
      :groupDefaultExpanded="1"
      @row-clicked="onRowClicked"
      :detailCellRendererParams="detailCellRendererParams"
      :getRowHeight="getRowHeight"
      :autoGroupColumnDef="autoGroupColumnDef"
      :suppressAggFuncInHeader="true"
      :rowClassRules="rowClassRules"
      :icons="icons"
    >
    </ag-grid-vue>

    <ag-grid-vue 
      v-show="viewOpenInvoices"
      class="app-table ag-theme-alpine"
      :columnDefs="columnDefs_openInvoices"
      :rowData="rowData_openInvoices"
      @grid-ready="onGridReady_openInvoices"
      rowSelection="single"
      :sideBar="sideBar"
      :masterDetail="false"
      :groupDefaultExpanded="1"
      :autoGroupColumnDef="autoGroupColumnDef"
      :suppressAggFuncInHeader="true"
      :rowClassRules="rowClassRules"
      :icons="icons"
    >
    </ag-grid-vue>

    <InvoiceEditPopup 
      v-if="showInvoiceEditPopup" 
      @close="closeInvoiceEditPopup(false)"
      @close-reload="closeInvoiceEditPopup(true)"
      :edit="edit"
      :selectedInvoice="selectedInvoice"
      :selectedOrder="selectedOrder"
    ></InvoiceEditPopup>

    <GenericPopup v-if="invoiceToBeCanceled" @close="invoiceToBeCanceled = null" width="20vw">
      <b-col>
        <b-row style="display: flex;justify-content: center;text-align: center;">
          Soll die Rechnung (R-Nr.:{{invoiceToBeCanceled.InvoiceCode}}) storniert werden?
        </b-row>
        <b-row style="display: flex;justify-content: center;">
          <b-button class="m-2" @click="cancelInvoice()" variant="danger">Stornieren</b-button>
          <b-button class="m-2" @click="invoiceToBeCanceled = null">Abbrechen</b-button>
        </b-row>
      </b-col>
    </GenericPopup>
  </div>
</template>

<script>
import ControllingService from "../services/ControllingService";
import EventBus from "../services/EventBus";

import { AgGridVue } from "ag-grid-vue";
import 'ag-grid-enterprise';

import CancelInvoiceCellRenderer from "./CellRenderer/CancelInvoiceCellRenderer";
import CurrencyCellRenderer from "./CellRenderer/CurrencyCellRenderer";
import DateCellRenderer from "./CellRenderer/DateCellRenderer";
import EditInvoiceCellRenderer from "./CellRenderer/EditInvoiceCellRenderer";
import PayInvoiceCellRenderer from "./CellRenderer/PayInvoiceCellRenderer";
import PercentageCellRenderer from "./CellRenderer/PercentageCellRenderer";
import ReminderLevelCellRenderer from "./CellRenderer/ReminderLevelCellRenderer";

import ConfigurationToolPanel from "./ConfigurationToolPanel";
import GenericPopup from "./GenericPopup";
import InvoiceEditPopup from "./InvoiceEditPopup";

export default {
  name: "InvoiceManagement",
  components: {
    AgGridVue,
    cancelInvoiceCellRenderer: CancelInvoiceCellRenderer,
    ConfigurationToolPanel,
    currencyCellRenderer: CurrencyCellRenderer,
    dateCellRenderer: DateCellRenderer,
    editInvoiceCellRenderer: EditInvoiceCellRenderer,
    GenericPopup,
    InvoiceEditPopup,
    payInvoiceCellRenderer: PayInvoiceCellRenderer,
    percentageCellRenderer: PercentageCellRenderer,
    reminderLevelCellRenderer: ReminderLevelCellRenderer,
  },
  data() {
    return {
      autoGroupColumnDef: {
        width: 0,
      },
      detailCellRendererParams: null,
      edit: false,
      filter: {
        invoiceDateStart: null,
        invoiceDateEnd: null,
      },
      getRowHeight: null,
      gridApi: null,
      gridApi_openInvoices: null,
      gridColumnApi: null,
      gridColumnApi_openInvoices: null,
      icons:{
        cog: "<i class='cog-via-css'/>"
      },
      invoiceToBeCanceled: null,
      isLoading: false,
      rowClassRules: {
        'first-row': params => params.rowIndex === 0,
      },
      rowData: null,
      rowData_openInvoices: null,
      selectedInvoice: null,
      selectedOrder: null,
      selectedYear: null,
      showInvoiceEditPopup: false,
      sideBar: {
        toolPanels: [
          {
            id: 'configuration',
            labelDefault: 'Konfiguration',
            labelKey: 'configuration',
            iconKey: 'cog',
            toolPanel: 'ConfigurationToolPanel',
            toolPanelParams: {
              parentName: this.$options.name,
            },
          },
          {
            id: 'columns',
            labelDefault: 'Spalten',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: false,
              suppressColumnSelectAll: false,
              suppressColumnExpandAll: false,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filter',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: false,
              suppressFilterSearch: false,
            },
          },
        ],
      },
      viewOpenInvoices: false,
    }
  },
  created() {
    this.getRowHeight = (params) => {
      if (params.node?.detail && params.data?.Orders) {
        var offset = 80;
        var allDetailRowHeight =
          (params.data.Orders.length <= 10 ? params.data.Orders.length : 10) *
          params.api.getSizesForCurrentTheme().rowHeight;
        var gridSizes = params.api.getSizesForCurrentTheme();
        return (
          allDetailRowHeight +
          ((gridSizes && gridSizes.headerHeight) || 0) +
          offset
        );
      }
    };

    EventBus.$on('showInvoiceEditPopup', data => {
      this.edit = true;
      this.selectedInvoice = data;
      this.showInvoiceEditPopup = true;
    });
    EventBus.$on('cancelInvoice', invoice => {
      this.invoiceToBeCanceled = invoice;
    });
    EventBus.$on('payInvoice', invoice => {
      invoice.IsPaid = !invoice.IsPaid;
      this.gridApi_openInvoices.refreshCells();

      ControllingService.setInvoice({
        InvoiceId: invoice.InvoiceId,
        OrderId: invoice.OrderId,
        InvoiceCode: invoice.InvoiceCode,
        InvoiceAmount: invoice.InvoiceAmount,
        IsPaid: invoice.IsPaid,
        Year: invoice.Year,
        InvoiceDate: invoice.InvoiceDate,
        DueDate: invoice.DueDate,
        Note: invoice.Note,
        InvoiceAmountGross: invoice.InvoiceAmountGross
      })
      .then(() => {
        // this.loadRowData();
      })
      .catch(err => {
        console.error(err);
      })
    });
    EventBus.$on('updateReminderLevel', (invoice, newLevel) => {
      var oldLevel = invoice.ReminderLevel;
      if(oldLevel == newLevel) {
        newLevel--;
      }
      invoice.ReminderLevel = newLevel
      this.gridApi_openInvoices.refreshCells();

      ControllingService.setInvoiceReminderLevel(
        invoice.InvoiceId,
        invoice.ReminderLevel
      )
      .then(() => {
        // this.loadRowData();
      })
      .catch(err => {
        console.error(err);
      })
    });
  },
  beforeMount() {
    this.selectedYear = new Date().getFullYear();
    this.loadRowData();
  },
  mounted() {
    this.detailCellRendererParams = {
      // level 2 grid options
      detailGridOptions: {
        columnDefs: [
          { headerName: 'Rechnungsnummer', field: 'InvoiceCode', resizable: true },
          { headerName: 'Summe netto', field:'InvoiceAmount', cellRenderer: 'currencyCellRenderer', resizable: true },
          { headerName: 'Summe brutto', field:'InvoiceAmountGross', cellRenderer: 'currencyCellRenderer', resizable: true },
          { headerName: 'Datum', field:'InvoiceDate', resizable: true, cellRenderer: 'dateCellRenderer' },
          { headerName: 'Bezahlt', field:'IsPaid', resizable: true, cellRenderer: params => {return params.data.IsPaid ? 'Ja' : 'Nein'}},
          { headerName: 'Fällig am', field:'DueDate', resizable: true, cellRenderer: 'dateCellRenderer' },
          { headerName: '', field:'IsCanceled', cellRenderer: params => {return params.value ? '<b style="font-size:14pt;color:#ff4343;">STORNIERT<b/>' : ''}},
          { headerName: '', field:'IsCanceled', cellRenderer: 'editInvoiceCellRenderer', width: 50},
          { headerName: '', field:'IsCanceled', cellRenderer: 'cancelInvoiceCellRenderer', width: 50}
        ],
        defaultColDef: {
          flex: 1,
        },
        rowSelection: 'single'
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.Invoices);
      },
    };
    EventBus.$on(`${this.$options.name}_newConfig`, params => {
      this.selectedYear = params.selectedYear;
      this.filter.invoiceDateStart = params.invoiceDateStart;
      this.filter.invoiceDateEnd = params.invoiceDateEnd;
      this.loadRowData();
    })
  },
  computed: {
    columnDefs() {
      return [
        { headerName: "AggregationGroup", field: 'AggregationGroup', rowGroup: true, hide: true, suppressColumnsToolPanel: true},
        { headerName: "Kunde", field: "ClientName", sortable: true, filter: true, width:200, resizable: true },
        { headerName: "Projekt", field: "ProjectName", sortable: true, filter: true, width:250, resizable: true },
        { headerName: "Unterprojekt", field: "SubProjectName", sortable: true, filter: true, width:150, resizable: true },
        { headerName: "Auftrag", field: "OrderName", sortable: true, filter: true, width:150, resizable: true },
        { headerName: "Kostenstelle", field: "FullCostCenterCode", sortable: true, filter: true, width: 130, resizable: true },
        { headerName: "CL", field: "Consultant", sortable: true, filter: true, width:70, resizable: true },
        { headerName: "Budget", field: "Budget", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
        { headerName: "Abgerechnet", field: "SumInvoiceAmount", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
        { headerName: "Offener Betrag (€)", field: "SumInvoiceAmountNotPaid", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
        { headerName: "Offener Betrag (%)", field: "SumInvoiceAmountNotPaid_Percent", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'percentageCellRenderer',
          aggFunc: params => {
            let total = 0;
            let count = 0;
            params.values.forEach(value => {if(value) {total += value; count++;}});
            return (total / count).toFixed(2);
          }  
        },
        { headerName: "Rechnungen offen", field: "CountInvoiceNotPaid", sortable: true, filter: true, width:200, resizable: true, aggFunc: 'sum', type: 'rightAligned' },
        { headerName: "Rechnungen bezahlt", field: "CountInvoicePaid", sortable: true, filter: true, width:200, resizable: true, aggFunc: 'sum', type: 'rightAligned' }
      ];
    },
    columnDefs_openInvoices() {
      return [
        { headerName: "AggregationGroup", field: 'AggregationGroup', rowGroup: true, hide: true, suppressColumnsToolPanel: true},
        { headerName: 'Mahnungsstufe', field:'ReminderLevel', width:150, resizable: true, cellRenderer: 'reminderLevelCellRenderer'},
        { headerName: 'Bezahlt', field:'IsPaid', width:100, resizable: true, cellRenderer: 'payInvoiceCellRenderer'},
        { headerName: "Kunde", field: "ClientName", sortable: true, filter: true, width:200, resizable: true },
        { headerName: "Projekt", field: "ProjectName", sortable: true, filter: true, width:250, resizable: true },
        { headerName: "Unterprojekt", field: "SubProjectName", sortable: true, filter: true, width:150, resizable: true },
        { headerName: "Auftrag", field: "OrderName", sortable: true, filter: true, width:150, resizable: true },
        { headerName: "Kostenstelle", field: "FullCostCenterCode", sortable: true, filter: true, width: 130, resizable: true },
        { headerName: 'Rechnungsnummer', field: 'InvoiceCode', sortable: true, filter: true, resizable: true },
        { headerName: 'Summe netto', field:'InvoiceAmount', cellRenderer: 'currencyCellRenderer', sortable: true, filter: true, resizable: true, aggFunc: 'sum' },
        { headerName: 'Summe brutto', field:'InvoiceAmountGross', cellRenderer: 'currencyCellRenderer', sortable: true, filter: true, resizable: true, aggFunc: 'sum' },
        { headerName: 'Datum', field:'InvoiceDate', sortable: true, filter: true, resizable: true, cellRenderer: 'dateCellRenderer' },
        { headerName: 'Fällig am', field:'DueDate', sortable: true, filter: true, resizable: true, cellRenderer: 'dateCellRenderer' },
        // { headerName: '', field:'IsCanceled', cellRenderer: params => {return params.value ? '<b style="font-size:14pt;color:#ff4343;">STORNIERT<b/>' : ''}},
        // { headerName: '', field:'IsCanceled', cellRenderer: 'editInvoiceCellRenderer', width: 50},
        // { headerName: '', field:'IsCanceled', cellRenderer: 'cancelInvoiceCellRenderer', width: 50}
      ];
    },
  },
  watch: {
    viewOpenInvoices() {
      this.loadRowData();
    },
    $route (to, from){
      if (this.$router.currentRoute.path == "/invoiceManagement") {
        this.loadRowData();
      }
    }
  },
  methods: {
    cancelInvoice() {
      ControllingService.cancelInvoice(this.invoiceToBeCanceled.InvoiceId)
      .then(() => {
        this.invoiceToBeCanceled = null;
        this.loadRowData();
      })
      .catch(err => {
        console.error(err);
      })
    },
    closeInvoiceEditPopup(reload) {
      this.showInvoiceEditPopup = false;
      if(reload) this.loadRowData();
    },
    loadRowData() {
      if(this.gridApi) this.gridApi.showLoadingOverlay();
      if(this.gridApi_openInvoices) this.gridApi_openInvoices.showLoadingOverlay();

      ControllingService.getSubprojectAndOrder(null, null, this.filter.invoiceDateStart, this.filter.invoiceDateEnd, this.selectedYear)
      .then(res => {
        this.rowData = [];
        this.rowData_openInvoices = [];
        if(res.data === '') return;
        res?.data?.forEach(subproject => {
          subproject?.Orders?.forEach(order => {
            this.rowData.push({
              ...order,
              ...{
                isSelectedForAggregation: false,
                AggregationGroup: 'Gesamt',
              }
            });
            order.Invoices?.forEach(invoice => {
              if(!invoice?.IsPaid && !invoice?.IsCanceled) {
              // if(!invoice?.isCanceled) {
                this.rowData_openInvoices.push({
                  ...invoice, 
                  ...{
                    ClientName: order.ClientName,
                    OrderName: order.OrderName,
                    ProjectName: order.ProjectName,
                    SubProjectName: order.SubProjectName,
                    FullCostCenterCode: order.FullCostCenterCode,
                    isSelectedForAggregation: false,
                    AggregationGroup: 'Gesamt',
                  }
                })
              }
            })
          })
        })
      })
      .finally(() => {
        if(this.selectedOrder != null) {
          // reopen selected node
          this.gridApi.forEachNode( node => {
            if(node.rowIndex === 0) {return};
            if(node.data.OrderId === this.selectedOrder.OrderId) {
              node.setExpanded(true);
              node.setSelected(true);
              this.gridApi.ensureIndexVisible(node.rowIndex, 'middle');
            } else {
              node.setExpanded(false);
              node.setSelected(false);
            }
          });
          this.gridApi.onGroupExpandedOrCollapsed();
          this.gridApi.forEachDetailGridInfo(detailGridInfo => {
            this.detailGridInfo =  detailGridInfo;
          });
        };

        if(this.gridApi) this.gridApi.hideOverlay();
        if(this.gridApi_openInvoices) this.gridApi_openInvoices.hideOverlay();
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onGridReady_openInvoices(params) {
      this.gridApi_openInvoices = params.api;
      this.gridColumnApi_openInvoices = params.columnApi;
    },
    onRowClicked() {
      // prevent for first row, aggregation row
      if(this.gridApi.getFocusedCell().rowIndex === 0) {return;}

      // collapse other rows on selectionchange
      this.gridApi.forEachNode(function (node) {
        if(node.rowIndex === 0) {return};
        if(node.selected === true) {
          node.expanded = !node.expanded;
        } else {
          node.expanded = false;
        }
      });
      this.gridApi.onGroupExpandedOrCollapsed();

      this.selectedOrder = this.gridApi.getSelectedRows()[0];
    },
    toggleInvoiceEditPopup() {
      this.edit = false;
      this.showInvoiceEditPopup = !this.showInvoiceEditPopup;
    },
  },
}
</script>

<style scoped>
.table-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button-container {
  position: absolute;
  translate: -50%;
  left: 50%;
}
</style>