<template>
  <b-card no-body v-draggable class="popup text-dark" :style="'width: ' + width">
    <b-card-header
      style="height: 25px; user-select: none;"
      class="pl-2 pb-0 pt-0"
      header-tag="div"
    >
      <b-row class="mx-0 px-0">
        <b-col class="mx-0 px-0" cols="11">
          {{ title }}
        </b-col>
        <b-col class="mx-0 px-0" cols="1" style="text-align: right">
          <font-awesome-icon
            class="mx-1 clickable"
            icon="fa-solid fa-xmark"
            @click="$emit('close')"
            style="cursor:pointer;"
          />
        </b-col>
      </b-row>
    </b-card-header>

    <b-card-body :style="`max-height: ${max_height}; overflow: auto;`">
      <slot></slot>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "GenericPopup",
  props: {
    title: String,
    width: {
      type: String,
      default: '75vw'
    },
    max_height: {
      type: String,
      default: '50vh'
    },

  }
}
</script>

<style scoped>
  .popup {
    width: 450px;
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  }
</style>