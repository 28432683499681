<template>
  <b-card no-body v-draggable class="popup text-dark">
    <b-card-header
      style="height: 25px; user-select: none;"
      class="pl-2 pb-0 pt-0"
      header-tag="div"
    >
      <b-row class="mx-0 px-0">
        <b-col class="mx-0 px-0" cols="11">
          Organisation {{edit ? 'bearbeiten' : 'erstellen'}}
        </b-col>
        <b-col class="mx-0 px-0" cols="1" style="text-align: right">
          <font-awesome-icon
            class="mx-1 clickable"
            icon="fa-solid fa-xmark"
            @click="$emit('close')"
            style="cursor:pointer;"
          />
        </b-col>
      </b-row>
    </b-card-header>

    <b-card-body style="overflow-y: auto;">
      <b-form @submit="onSubmit">
        <b-row>
          <b-col>
            <label for="input-project">Kunden:</label>
            <treeselect 
              id="input-project"
              v-model="organization.ClientIds" 
              placeholder="Kunden..."
              :options="clientList_treeselect"
              :loadOptions="loadOptions"
              :multiple="true"
              :disable-branch-nodes="true"
              required
            />
            <br>
            <b-form-group
              label="Organisations-Code:"
              label-for="input-vl"
            >
              <b-form-input
                id="input-vl"
                v-model="organization.OrganizationCode"
                type="number"
                placeholder="Organisations-Code..."
                :step="1"
                :min="100"
                :max="999"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Organisationsname:"
              label-for="input-vl"
            >
              <b-form-input
                id="input-vl"
                v-model="organization.OrganizationName"
                type="text"
                placeholder="Organisationsname..."
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row style="justify-content: right;">
          <b-button class="m-2" type="submit" variant="primary">Speichern</b-button>
          <!-- <b-button class="m-2" type="submit" :disabled="formChanges === 0">Speichern</b-button> -->
          <b-button class="m-2" @click="$emit('close')" variant="secondary">Abbrechen</b-button>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import ControllingService from '../services/ControllingService';
import Treeselect from '@riophae/vue-treeselect';

export default {
  name: "OrganizationEditPopup",
  components: {
    Treeselect
  },
  props: {
    edit: false,
    selectedOrder: {},
    selectedOrganization: null
  },
  data() {
    return {
      clientList_treeselect: null,
      formChanges: 0,
      isLoading: false,
      organization: {
        OrganizationId: null,
        OrganizationName: null,
        OrganizationCode: null,
        ClientIds: null,
      },
    }
  },
  watch: {
    organization: {
      handler() {
        this.formChanges++;
      },
      deep: true
    },
  },
  mounted() {
    
    if(this.selectedOrganization && this.edit){
      this.organization = this.selectedOrganization;
      this.organization.ClientIds = this.selectedOrganization.Clients.map(c => {
        return c.ClientId;
      })
    };

    // wait for all to be mounted
    setTimeout(() => {
      this.formChanges = 0;
    }, 100);
  },
  methods: {
    loadOptions({callback}){
      this.isLoading = true;
      ControllingService.getClientWithProjectAndSubProject()
      .then((res) => {
        this.clientList_treeselect = res.data.map(item => {
          return { 
            id: item.ClientId, 
            label: item.ClientName, 
            clientCode: item.ClientCode,
          };
        });
      })
      .finally(() => {
        this.isLoading = false;
        callback();
      })
    },
    onSubmit(event) {
      event.preventDefault();
      this.validateOrganization();
      let o = this.organization;
      o.ClientIds = o.ClientIds.toString();
      ControllingService.setOrganization(o)
      .then(() => {
        this.$emit('close');
      })
      .catch(err => {
        console.error(err);
      });
    },
    validateOrganization() {
      // remove empty strings
      for (var prop in this.organization) {
        if (Object.prototype.hasOwnProperty.call(this.organization, prop)) {
          if(this.organization[prop] === '') this.organization[prop] = null;
        }
      }
    }
  },
}
</script>

<style scoped>
.popup {
  width: 40vw;
  max-height: 80%;
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}
.upload-button-container {
  position: absolute;
  z-index: 10;
  transform: translate(0%,-111%);
  width: 90%;
  text-align: right;
}
.clickable {
  cursor: pointer;
}
.disabled-color {
  color: grey;
}
</style>