const packageConfig = require("../../package.json");

export default {
  methods: {
    getCustomLogoUrl() {
      return null;
    },

    getEmail() {
      return 'msmsupport@msm-network.com';
    },

    getFrontendVersion() {
      return this.frontendVersion;
    },

    isDemo() {
      if (APP_TARGET === "demo") return true;
      else return false;
    },
  },
  data() {
    return {
      frontendVersion: packageConfig.version,
      toolTipDelay: { delay: { "show": 500, "hide": 100 } },
    };
  },
};
