<template>
  <b-card no-body v-draggable class="popup text-dark">
    <b-card-header
      style="height: 25px; user-select: none;"
      class="pl-2 pb-0 pt-0"
      header-tag="div"
    >
      <b-row class="mx-0 px-0">
        <b-col class="mx-0 px-0" cols="11">
          {{!edit ? 'Unterprojekt erstellen' : 'Unterprojekt bearbeiten'}}
          <font-awesome-icon 
            v-if="subProjectHistory"
            class="mx-2 clickable"
            icon="fa-solid fa-book"
            style="cursor:pointer;"
            @click="showHistory = !showHistory"
          />
        </b-col>
        <b-col class="mx-0 px-0" cols="1" style="text-align: right">
          <font-awesome-icon
            class="mx-1 clickable"
            icon="fa-solid fa-xmark"
            @click="$emit('close')"
            style="cursor:pointer;"
          />
        </b-col>
      </b-row>
    </b-card-header>

    <b-card-body style="overflow-y: auto;">
      <b-form @submit="onSubmit">
        <b-row>
          <b-col>
            <label for="input-project">Kunde/Projekt:</label>
            <treeselect 
              id="input-project"
              v-model="subProject.ProjectId" 
              placeholder="Kunde/Projekt..."
              :options="clientProjectList_treeselect"
              :loadOptions="null"
              :multiple="false"
              :disable-branch-nodes="true"
              required
              :disabled="edit"
              :max-height="200"
            />
          </b-col>
          <b-col>
            <b-form-group
              label="Unterprojekt-Name:"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="subProject.SubProjectName"
                type="text"
                placeholder="Unterprojekt-Name..."
                :state="subProjectNameAvailable"
                @blur="getSubProjectNameAvailable()"
                required
              ></b-form-input>
              <b-form-invalid-feedback id="input-name">
                Name bereits vergeben.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <b-form-group
              label="Consultant:"
              label-for="input-vl"
            >
              <b-form-input
                id="input-vl"
                type="text"
                v-model="subProject.Consultant"
                placeholder="Consultant..."
                lazy-formatter
                :formatter="formatterConsultant"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Wahrscheinlichkeit (%):"
              label-for="input-odds"
            >
              <b-form-select
                id="input-probability"
                v-model="subProject.Probability"
                :options="probabilityList"
                placeholder="Wahrscheinlichkeit..."
                required
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col style="margin: auto;" v-if="edit">
            <b-button class="m-2" variant="outline-danger" @click="showDeletePopup = true">
              <font-awesome-icon
                class="mx-1 clickable"
                icon="fa-solid fa-trash"
                style="font-size: 14pt;"
              />
              <span>Löschen</span>
            </b-button>
            <b-button class="m-2" variant="outline-success" @click="showArchivePopup = true">
              <font-awesome-icon
                class="mx-1 clickable"
                icon="fa-solid fa-box-archive"
                style="font-size: 14pt;"
              />
              <span>Archivieren</span>
            </b-button>
          </b-col>
          <b-col style="text-align:end">
            <b-button class="m-2" type="submit" variant="primary" :disabled="formChanges === 0 || subProjectNameAvailable === false">Speichern</b-button>
            <b-button class="m-2" @click="$emit('close')" variant="secondary">Abbrechen</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>

    <!-- History Popup -->
    <GenericPopup v-if="showHistory" @close="showHistory = false">
      <div v-for="e in subProjectHistory" :key="e.index">
        {{e.FirstName}} {{e.LastName}} {{e.Timestamp}}: 
        <br>
        {{e.SubProjectJson}}
        <hr>
      </div>
    </GenericPopup>

    <!-- Delete Popup -->
    <GenericPopup v-if="showDeletePopup" @close="showDeletePopup = false" width="25vw">
      <b-col>
        <b-row style="display: flex;justify-content: center;text-align: center;">
          <span v-if="!subProject.HasSalary">Soll das Unterprojekt gelöscht werden?</span>
          <span v-else-if="subProject.HasSalary">
            Es gibt Zeiteinträge für dieses Unterprojekt.
            <br> Es kann nicht gelöscht werden.
          </span>
        </b-row>
        <b-row style="display: flex;justify-content: center; align-items:center">
          <font-awesome-icon 
            v-if="subProject.HasSalary"
            class="mx-2"
            icon="fa-solid fa-lock"
          />
          <b-button class="m-2" variant="danger" @click="setSubProjectInactive()" :disabled="subProject.HasSalary">Löschen</b-button>
          <b-button class="m-2" @click="showDeletePopup = false" >Abbrechen</b-button>
        </b-row>
      </b-col>
    </GenericPopup>

    <!-- Archive Popup -->
    <GenericPopup v-if="showArchivePopup" @close="showArchivePopup = false" width="25vw">
      <b-col>
        <b-row style="display: flex;justify-content: center;text-align: center;">
          Soll das Unterprojekt archiviert werden?
        </b-row>
        <b-row style="display: flex;justify-content: center;">
          <b-button class="m-2" variant="success" @click="setSubProjectIsArchived()">Archivieren</b-button>
          <b-button class="m-2" @click="showArchivePopup = false" >Abbrechen</b-button>
        </b-row>
      </b-col>
    </GenericPopup>
  </b-card>
</template>

<script>
import AuthenticationService from '../services/AuthenticationService';
import ControllingService from '../services/ControllingService';

import Treeselect from '@riophae/vue-treeselect';

import GenericPopup from './GenericPopup';

export default {
  name: "SubProjectEditPopup",
  components: {
    GenericPopup,
    Treeselect,
  },
  props: {
    clientProjectList_treeselect: {},
    edit: false,
    selectedSubproject: {}
  },
  data() {
    return {
      clientCode: {
        ClientCode: null,
        SubProjectCode: null,
      },
      clientCodeLocked: false,
      countryList: null,
      formChanges: 0,
      probabilityList: [],
      showArchivePopup: false,
      showDeletePopup: false,
      showHistory: false,
      subProject: {
        SubProjectId: null,
        ProjectId: null,
        SubProjectName: null,
        SubProjectCode: null,
        Consultant: null,
        Probability: null,
      },
      subProjectHistory: null,
      subProjectNameAvailable: null
    }
  },
  mounted() {
    var enableSave = false;
    if(this.selectedSubproject && this.edit){
      this.subProject = JSON.parse(JSON.stringify(this.selectedSubproject));

      // get SubProject History
      if(this.hasPermission('Management')) {
        ControllingService.getSubprojectHistory(this.selectedSubproject.SubProjectId)
        .then(res => {
          if(res.data) {
            this.subProjectHistory = res.data;
            this.subProjectHistory.forEach(h => {
              h.SubProjectJson = JSON.parse(h.SubProjectJson);
            });
          }
        });
      }
    }
    
    // getCountryList for country selector
    ControllingService.getCountryList()
    .then(res => {
      this.countryList = res.data;
      
      // unshift placeholder
      this.countryList.unshift({ value: null, text: 'Land...', disabled: true})
    });

    // if its an existing SubProject 
    switch (this.subProject.Probability) {
      case 0:
        this.probabilityList = [
          {text: '0% (Angebot abgelehnt)', value: 0},
          {text: '50% (Angebot bei Kunde)', value: 50},
          {text: '100% (Angebot angenommen)', value: 100},
        ];
        break;
      case 50:
        this.probabilityList = [
          {text: '0% (Angebot abgelehnt)', value: 0},
          {text: '50% (Angebot bei Kunde)', value: 50},
          {text: '100% (Angebot angenommen)', value: 100},
        ];
        break;
      case 100:
        this.probabilityList = [
          {text: '0% (Angebot abgelehnt)', value: 0},
          {text: '50% (Angebot bei Kunde)', value: 50},
          {text: '100% (Angebot angenommen)', value: 100},
        ];
        break;
      default:
        this.probabilityList = [
          {disabled: true, text: 'Wahrscheinlichkeit...', value: null},
          {text: '0% (Angebot abgelehnt)', value: 0},
          {text: '50% (Angebot bei Kunde)', value: 50},
          {text: '100% (Angebot angenommen)', value: 100},
        ]
        break;
    }
    
    // wait for all to be mounted
    setTimeout(() => {
      this.formChanges = 0;
      if(enableSave) this.formChanges++
    }, 100);
  },
  watch: {
    'subProject.ProjectId'() {
      var selectedClient = this.clientProjectList_treeselect.find(client => client.children.find(project => project.id === this.subProject.ProjectId ));
      if(selectedClient) {
        if(selectedClient.clientCode) {
          this.clientCodeLocked = true;
          this.clientCode.ClientCode = selectedClient.clientCode;
        } else {
          this.clientCodeLocked = false;
          this.clientCode.ClientCode = null;
        }
        this.clientCode.ClientId = selectedClient.id;
      } else {
        this.clientCodeLocked = false;
        this.clientCode.ClientCode = null;
        this.clientCode.ClientId = null;
      }
    },
    subProject: {
      handler() {
        this.formChanges++;
      },
      deep: true
    },
  },
  methods: {
    formatterConsultant(value) {
      let threeChars = /^[a-zA-Z]{3}$/;
      let twoChars = /^[a-zA-Z]{2}$/;

      if(threeChars.test(value)) return value;
      if(twoChars.test(value)) return value;
      
      return '';
    },
    hasPermission(permission) {
      return AuthenticationService.hasPermission(permission);
    },
    getSubProjectNameAvailable() {
      if(this.formChanges > 0 && this.subProject.ProjectId && this.subProject.SubProjectName) {
        ControllingService.getSubProjectNameAvailable({ProjectId: this.subProject.ProjectId, SubProjectId: this.subProject.SubProjectId, SubProjectName: this.subProject.SubProjectName})
        .then(res => {
          if(!res.data.SubProjectNameAvailable) {
            this.subProjectNameAvailable = false;
          } else {
            this.subProjectNameAvailable = null;
          }
        })
      }
    },
    onSubmit(event) {
      event.preventDefault()
      this.validateSubProject();
      ControllingService.setSubProject(this.subProject)
      .then(() => {
        if(this.subProject.Probability == 100) {
          ControllingService.setClientCode(this.clientCode)
          .then(() => {
            this.$emit('close-reload');
          })
        } else {
          this.$emit('close-reload');
        }
      });
    },
    setSubProjectInactive() {
      ControllingService.setSubprojectInactive(this.subProject.SubProjectId)
      .then(() => {
        this.$emit('close-reload');
      })
    },
    setSubProjectIsArchived() {
      ControllingService.setSubprojectIsArchived(this.subProject.SubProjectId, true)
      .then(() => {
        this.$emit('close-reload');
      })
    },
    validateSubProject() {
      // remove empty strings
      for (var prop in this.subProject) {
        if (Object.prototype.hasOwnProperty.call(this.subProject, prop)) {
          if(this.subProject[prop] === '') this.subProject[prop] = null;
        }
      }
    },
  },
}
</script>

<style scoped>
.popup {
  width: 80vw;
  max-height: 80%;
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}
</style>