<template>
  <b-form-input
    ref="input"
    type="number"
    v-model="value"
    :step="0.01"
    :min="0"
  ></b-form-input>
</template>

<script>
import Vue from 'vue'
export default {
  data() {
    return {
      value: null
    };
  },
  mounted() {
    this.value = this.params.value;
    Vue.nextTick(() => this.$refs.input.focus());
  },
  methods: {
    getValue() {
      return this.value;
    }
  }
}
</script>