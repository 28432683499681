<template>
  <b-card no-body v-draggable class="popup text-dark">
    <b-card-header
      style="height: 25px; user-select: none;"
      class="pl-2 pb-0 pt-0"
      header-tag="div"
    >
      <b-row class="mx-0 px-0">
        <b-col class="mx-0 px-0" cols="11">
          Rechnung {{edit ? 'bearbeiten' : 'erstellen'}}
        </b-col>
        <b-col class="mx-0 px-0" cols="1" style="text-align: right">
          <font-awesome-icon
            class="mx-1 clickable"
            icon="fa-solid fa-xmark"
            @click="$emit('close')"
            style="cursor:pointer;"
          />
        </b-col>
      </b-row>
    </b-card-header>

    <b-card-body style="overflow-y: auto;">
      <p>Rechnung für: <b>{{selectedOrder.SubProjectName + ' - ' + selectedOrder.OrderName}}</b></p>
      <b-form @submit="onSubmit">
        <b-row>
          <b-col>
            <!-- Input InvoiceCode -->
            <b-form-group
              label="Rechnungsnummer:"
              label-for="input-invoiceCode"
            >
              <b-form-input
                id="input-invoiceCode"
                v-model="invoice.InvoiceCode"
                type="text"
                placeholder="Rechnungsnummer..."
                required
              ></b-form-input>
            </b-form-group>
            <!-- Input amount -->
            <b-form-group
              label="Summe netto:"
              label-for="input-invoiceAmount"
            >
              <b-form-input
                id="input-invoiceAmount"
                v-model="invoice.InvoiceAmount"
                type="number"
                placeholder="Summe netto..."
                required
                :step="0.01"
                :min="0"
              ></b-form-input>
            </b-form-group>
            <!-- Input amount gross -->
            <b-form-group
              label="Summe brutto:"
              label-for="input-invoiceAmountGross"
            >
              <b-form-input
                id="input-invoiceAmountGross"
                v-model="invoice.InvoiceAmountGross"
                type="number"
                placeholder="Summe brutto..."
                required
                :step="0.01"
                :min="0"
              ></b-form-input>
            </b-form-group>
            <!-- Input isPaid -->
            <b-form-group
              label="Bezahlt:"
              label-for="input-isPaid"
              style="display: flex"
            >
              <b-form-checkbox
                id="input-isPaid"
                class="mx-2"
                v-model="invoice.IsPaid"
                required
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col>
            <!-- Input Year -->
            <b-form-group
              label="Wirtschaftsjahr:"
              label-for="input-invoiceYear"
            >
              <b-form-select
                id="input-invoiceYear"
                v-model="invoice.Year"
                :options="selectedOrder.AllYearsOfOrder"
                required
              ></b-form-select>
            </b-form-group>
            <!-- Input Date -->
            <b-form-group
              label="Datum:"
              label-for="input-invoiceDate"
            >
              <b-form-input
                id="input-invoiceDate"
                v-model="invoice.InvoiceDate"
                type="date"
                placeholder="Datum..."
                required
              ></b-form-input>
            </b-form-group>
            <!-- Input DueDate -->
            <b-form-group
              label="Fällig:"
              label-for="input-invoiceDate"
            >
              <b-form-input
                id="input-invoiceDate"
                v-model="invoice.DueDate"
                type="date"
                placeholder="Fällig..."
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Bemerkung:"
              label-for="input-note"
            >
              <b-form-textarea
                id="input-note"
                v-model="invoice.Note"
                v-html="invoice.Note"
                :state="(!invoice.Note || invoice.Note?.length <= 50)"
                placeholder="max. 50 Zeichen"
                no-resize
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row style="justify-content: right;">
          <b-button class="m-2" type="submit" variant="primary" :disabled="formChanges === 0 || invoice.Note?.length >= 50">Speichern</b-button>
          <b-button class="m-2" @click="$emit('close')" variant="secondary">Abbrechen</b-button>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import ControllingService from '../services/ControllingService';
import Treeselect from '@riophae/vue-treeselect';

export default {
  name: "InvoiceEditPopup",
  components: {
    Treeselect
  },
  props: {
    edit: false,
    selectedInvoice: null,
    selectedOrder: null
  },
  data() {
    return {
      formChanges: 0,
      invoice: {
        InvoiceAmount: null,
        InvoiceCode: null,
        InvoiceDate: null,
        IsPaid: false,
        Year: null,
        OrderId: null,
        DueDate: null,
        Note: '',
        InvoiceAmountGross: null
      }
    }
  },
  watch: {
    invoice: {
      handler() {
        this.formChanges++;
      },
      deep: true
    },
  },
  mounted() {
    if(this.selectedInvoice && this.edit){
      this.invoice = JSON.parse(JSON.stringify(this.selectedInvoice));
    } else {
      this.invoice.Year = this.selectedOrder.AllYearsOfOrder[this.selectedOrder.AllYearsOfOrder.length - 1]
    }
    this.invoice.OrderId = JSON.parse(JSON.stringify(this.selectedOrder.OrderId));
    if(this.invoice.Note === null) this.invoice.Note = '';

    // wait for all to be mounted
    setTimeout(() => {
      this.formChanges = 0;
    }, 100);
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      var invoiceData = JSON.parse(JSON.stringify(this.invoice));
      if(invoiceData.Note === '') invoiceData.Note = null;
      ControllingService.setInvoice(invoiceData)
      .then((res) => {
        this.$emit('close-reload');
      });
    },
  },
}
</script>

<style scoped>
.popup {
  width: 50vw;
  max-height: 80%;
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}
.upload-button-container {
  position: absolute;
  z-index: 10;
  transform: translate(0%,-111%);
  width: 90%;
  text-align: right;
}
.clickable {
  cursor: pointer;
}
.disabled-color {
  color: grey;
}
</style>