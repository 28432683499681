import Vue from 'vue';
import Router from 'vue-router';

import Home from '@/components/Home';
import Management from '@/components/Management'
import Marketing from '@/components/Marketing';
import ProjectManagement from '@/components/ProjectManagement';
import QualityAssurance from '@/components/QualityAssurance';
import Field from '@/components/Field';
import ShopperCosts from '@/components/ShopperCosts';
import OrderManagement from '@/components/OrderManagement';
import InvoiceManagement from '@/components/InvoiceManagement';
import CostCenterManagement from '@/components/CostCenterManagement';
import Archive from '@/components/Archive';
import Blank from '@/components/Blank';

import Imprint from '@/components/Imprint';
import DataProtection from '@/components/DataProtection';
import Page401 from '@/components/Page401';
import Page404 from '@/components/Page404';
import Page500 from '@/components/Page500';
import PageSignInFailed from '@/components/PageSignInFailed';
import Callback from '@/components/Callback';

import AppConfig from '@/mixins/AppConfig';

import AuthenticationService from '@/services/AuthenticationService';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',

  routes: [
    {
      path: '/',
      name: 'Home',
      meta: { MessageKey: 'Webportal.Home', requiresAuth: true },
      redirect: '/_', // Fallback will be applied while checking permission
      component: Home,
      children: [
        {
          path: '/_',
          name: 'blank',
          component: Blank
        },
        {
          path: '/management',
          name: 'management',
          meta: {checkPermission: ['Management']},
          component: Management
        },
        {
          path: '/sales',
          name: 'sales',
          meta: {checkPermission: ['Sales']},
          component: Marketing
        },
        {
          path: '/projectManagement',
          name: 'projectManagement',
          meta: {checkPermission: ['ProjectManagement']},
          component: ProjectManagement
        },
        {
          path: '/qualityAssurance',
          name: 'qualityAssurance',
          meta: {checkPermission: ['QualityAssurance']},
          component: QualityAssurance
        },
        {
          path: '/field',
          name: 'field',
          meta: {checkPermission: ['Field']},
          component: Field
        },
        {
          path: '/shopperCosts',
          name: 'shopperCosts',
          meta: {checkPermission: ['ShopperCosts']},
          component: ShopperCosts
        },
        {
          path: '/orderManagement',
          name: 'orderManagement',
          meta: {checkPermission: ['OrderManagement']},
          component: OrderManagement
        },
        {
          path: '/invoiceManagement',
          name: 'invoiceManagement',
          meta: {checkPermission: ['InvoiceManagement']},
          component: InvoiceManagement
        },
        {
          path: '/costCenterManagement',
          name: 'costCenterManagement',
          meta: {checkPermission: ['CostCenterManagement']},
          component: CostCenterManagement
        },
        {
          path: '/archive',
          name: 'archive',
          meta: {checkPermission: ['Archive']},
          component: Archive
        },
        {
          path: '/imprint',
          name: 'imprint',
          component: Imprint
        },
        {
          path: '/dataprotection',
          name: 'dataprotection',
          component: DataProtection
        },
      ]
    },
    {
      path: '/callback',
      component: {
        render(c) {
          // Check if already logged in. This was introduced because when pressing F5 after successful login in Firefox,
          // the callback URL was loaded again but resulted in an error "No matching state found in storage".
          AuthenticationService.getUser().then(() => {
            // If not, then continue callback workflow
            if (!AuthenticationService.isAuthenticated)
              return AuthenticationService.callback();
          }).then(() => {
            // App needs to be restarted (instead of $router.replace) to allow custom theme which is only evaluated once on startup
            if (AuthenticationService.redirectUri)
              location.replace(AuthenticationService.redirectUri);
            else
              location.replace('/');
          }).catch((e) => {
            if (e === 'unauthorized') {
              // Logout user after 5 seconds
              setTimeout(() => { AuthenticationService.logout(); }, 5000);

              this.$router.push({ name: 'unauthorized' });
            }
            else
              this.$router.push({ name: 'signinfailed' });
          });

          return c(Callback);
        }
      },
    },

    // HACK: AutoLogin SSO
    {
      path: '/isg',
      component: {
        render(c) {
          if (AuthenticationService.isAuthenticated) {
            location.replace('/');
          } else {
            let login_hint = this.$route.query.login_hint;
            if (login_hint) {
              AuthenticationService.login(login_hint).then(() => {
                if (AuthenticationService.isAuthenticated)
                  location.replace('/');
              });
            } else {
              this.$router.push({ name: 'error' });
            }
          }
        }
      },
    },

    // {
    //   path: '/download/:token',
    //   component: Download
    // },

    // ... the default route, when none of the above matches:
    {
      path: '*',
      name: 'notfound',
      component: Page404
    },

    {
      path: '*',
      name: 'error',
      component: Page500
    },

    {
      path: '*',
      name: 'unauthorized',
      component: Page401
    },

    {
      path: '*',
      name: 'signinfailed',
      component: PageSignInFailed
    },

    // {
    //   path: '*',
    //   name: 'signedout',
    //   component: ExternalSignedOut
    // },
  ]
});

// Check for authentication before navigating to target
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!AuthenticationService.isAuthenticated) {
      AuthenticationService.login().then(() => {
        if (AuthenticationService.isAuthenticated)
          next();
      }).catch((e) => {
        if (e === 'unauthorized') {
          // Logout user after 5 seconds
          setTimeout(() => { AuthenticationService.logout(); }, 5000);
          router.push({ name: 'unauthorized' });
        }
        else {
          router.push({ name: 'signinfailed' });
        }
      });
    }
    else {
      next();
    }
  }
  else
    next();
});

// Check for permissions
router.beforeEach((to, from, next) => {
  const violation = to?.meta?.checkPermission?.find((permission) =>
    AuthenticationService?.localUserProfile?.Permissions?.includes(permission) ? false : permission
  );

  if (violation) {
    next('/');
  }
  else next();
});

export default router;
