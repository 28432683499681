<template>
  <footer class="app-footer">
    <span class="mx-2">Loop Controlling v{{versions[0].Version}}
      <span class="mx-2 footer-link" @click="(showChangelog = !showChangelog)">Changelog</span>
    </span>
    <div class="footer-nav">
      <span class="mx-2 footer-link" @click="routerPush('/imprint')">Imprint</span>
      <span class="mx-2 footer-link" @click="routerPush('/dataprotection')">Data Protection</span>
    </div>
    <GenericPopupVue v-if="showChangelog" width="30vw" @close="(showChangelog = false)">
      <h4><b>Changelog</b></h4>
      <div v-for="(v, index) in versions" :key="index">
        <h4>v{{v.Version}}</h4>
        <p v-html="v.CreatedAt"></p>
        <div v-html="v.Changelog"></div>
        <hr>
      </div>
    </GenericPopupVue>
  </footer>
</template>

<script>
import ControllingService from "../services/ControllingService";
import GenericPopupVue from "./GenericPopup.vue";

export default {
  components: {
    GenericPopupVue
  },
  data() {
    return {
      showChangelog: false,
      versions: [
        {
          Version:"",
          Changelog:"",
          CreatedAt:""
        }
      ],
    }
  },
  created() {
    ControllingService.getVersion()
    .then(res => {
      this.versions = res.data;
      this.versions.forEach(v => {
        v.CreatedAt = new Date(Date.parse(v.CreatedAt)).toLocaleString();
      })
    });
  },
  methods: {
    routerPush(path) {
      if(this.$router.currentRoute.path != path) {
        this.$router.push(path);
      }
    }
  },
};
</script>

<style scoped>
.app-footer {
  position: fixed;
  z-index: 100;
  bottom: 0%;
  width: 100%;
  height: 40px;
  background-color: white;
  color: grey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.footer-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-link:hover{
  cursor: pointer;
  color: rgb(70, 70, 70)
}
</style>