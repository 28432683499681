<template>
  <div class="route-container">
    <ag-grid-vue 
      class="app-table ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="single"
      @grid-ready="onGridReady"
      @cell-value-changed="onCellValueChanged"
      :sideBar="sideBar"
      :icons="icons"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import ControllingService from "../services/ControllingService";
import EventBus from '@/services/EventBus.js';

import { AgGridVue } from "ag-grid-vue";

import CorrectionCellRenderer from "./CellRenderer/CorrectionCellRenderer";
import CurrencyCellRenderer from "./CellRenderer/CurrencyCellRenderer";
import DateCellRenderer from "./CellRenderer/DateCellRenderer";
import HourCellRenderer from "./CellRenderer/HourCellRenderer";
import SurveyInstanceCountCellRenderer from "./CellRenderer/SurveyInstanceCountCellRenderer";

import ConfigurationToolPanel from "./ConfigurationToolPanel";
import NumberCellEditor from "./CellEditor/NumberCellEditor";

export default {
  name: "Management",
  components: {
    AgGridVue,
    ConfigurationToolPanel,
    correctionCellRenderer: CorrectionCellRenderer,
    currencyCellRenderer: CurrencyCellRenderer,
    dateCellRenderer: DateCellRenderer,
    hourCellRenderer: HourCellRenderer,
    numberCellEditor: NumberCellEditor,
    surveyInstanceCountCellRenderer: SurveyInstanceCountCellRenderer,
  },
  data() {
    return {
      columnDefs: null,
      gridApi: null,
      gridColumnApi: null,
      filter: {
        invoiceDateStart: null,
        invoiceDateEnd: null,
      },
      icons:{
        cog: "<i class='cog-via-css'/>"
      },
      rowData: null,
      sideBar: {
        toolPanels: [
          {
            id: 'configuration',
            labelDefault: 'Konfiguration',
            labelKey: 'configuration',
            iconKey: 'cog',
            toolPanel: 'ConfigurationToolPanel',
            toolPanelParams: {
              parentName: this.$options.name,
            },
          },
          {
            id: 'columns',
            labelDefault: 'Spalten',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: false,
              suppressColumnSelectAll: false,
              suppressColumnExpandAll: false,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filter',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: false,
              suppressFilterSearch: false,
            },
          },
        ],
      },
      selectedYear: null,
    }
  },
  beforeMount() {
    this.selectedYear = new Date().getFullYear();
    this.filter.invoiceDateStart = new Date().getFullYear() + '-01-01';
    this.filter.invoiceDateEnd = new Date().getFullYear() + '-12-31';
    this.loadRowData();
    this.columnDefs = [
      { headerName: "CL", field: "Consultant", sortable: true, filter: true, width:70, resizable: true },
      { headerName: "Kostenstelle", field: "FullCostCenterCode", sortable: true, filter: true, width: 130, resizable: true },
      { headerName: "Auftrag", field: "OrderName", sortable: true, filter: true, width:150, resizable: true },
      { headerName: "Unterprojekt", field: "SubProjectName", sortable: true, filter: true, width:150, resizable: true },
      { headerName: "Projekt", field: "ProjectName", sortable: true, filter: true, width:250, resizable: true },
      { headerName: "Kunde", field: "ClientName", sortable: true, filter: true, width:200, resizable: true },
      { headerName: "Etat", field: "Budget", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' },
      { headerName: "In Rechnung gestellt", field: "SumInvoiceAmount", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' },
      { headerName: "Kosten PM", field: "SalaryProjectManagement.Salary", sortable: true, filter: true, width: 180, resizable: true, cellRenderer: 'currencyCellRenderer'},
      { headerName: "Kosten Feld", field: "SalaryField.Salary", sortable: true, filter: true, width: 180, resizable: true, cellRenderer: 'currencyCellRenderer'},
      { headerName: "TK Kosten", field: "OrderPayout", sortable: true, filter: true, width:180, resizable: true, cellRenderer: 'currencyCellRenderer' },
      { headerName: "Kosten QSS", field: "SalaryQualityAssurance.Salary", sortable: true, filter: true, width: 180, resizable: true, cellRenderer: 'currencyCellRenderer'},
      { headerName: "Gemeinkosten Ist", field: "AllocationOfOverheadCosts", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' },
      { headerName: "Fremdkosten Ist", field:"ThirdPartyServices", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' },
      { headerName: "Korrektur", field:"Correction", sortable: true, filter: true, width:150, resizable: true, 
        editable: true, 
        cellEditor: 'numberCellEditor',
        cellRenderer: 'currencyCellRenderer'
      },
      { headerName: "Notiz", field:"CorrectionNote", sortable: false, filter: false, width:150, resizable: true, editable: true },
      { headerName: "Ergebnis", field:"Result", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' },
    ];
  },
  mounted() {
    EventBus.$on(`${this.$options.name}_newConfig`, params => {
      this.selectedYear = params.selectedYear;
      this.filter.invoiceDateStart = params.invoiceDateStart;
      this.filter.invoiceDateEnd = params.invoiceDateEnd;
      this.loadRowData();
    })
  },
  watch: {
    $route (to, from){
      if (this.$router.currentRoute.path == "/management") {
        this.loadRowData();
      }
    }
  },
  methods: {
    loadRowData() {
      if(this.gridApi) this.gridApi.showLoadingOverlay();

      ControllingService.getSubprojectAndOrder(null, null, this.filter.invoiceDateStart, this.filter.invoiceDateEnd, this.selectedYear)
      .then(res => {
        this.rowData = [];
        if(res.data === '') return;
        res?.data?.forEach(subproject => {
          subproject?.Orders?.forEach(order => {
            this.rowData.push(order);
          })
        })
      })
      .finally(() => {
        if(this.gridApi) this.gridApi.hideOverlay();
      });
    },
    onCellValueChanged(params) {
      if(params.column.colId == "Correction" || params.column.colId == "CorrectionNote"){
        var correction = {
          "OrderId": params.data.OrderId,
          "Year": this.selectedYear,
          "Correction": params.data.Correction,
          "CorrectionNote": params.data.CorrectionNote,
        };
        ControllingService.setOrderYearCorrection(correction)
        .then(res => {
          console.log(res)
          if(res.status == 200) {
            // temporarily overwrite the old data.result with the new correct one, hence no reload is needed
            if(!this.rowData[params.rowIndex].oldResult) this.rowData[params.rowIndex].oldResult = this.rowData[params.rowIndex].Result;
            this.rowData[params.rowIndex].Result = this.rowData[params.rowIndex].oldResult + (params.data.Correction ? parseFloat(params.data.Correction) : 0);
            this.gridApi.refreshCells();
          }
        })
        .catch(err => {
          console.error(err);
        })
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
  },
}
</script>

<style>

</style>