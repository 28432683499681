<template>
  <div class="route-container">
    <ag-grid-vue 
      class="app-table ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      @grid-ready="onGridReady"
      @first-data-rendered="onFirstDataRendered"
      rowSelection="single"
      :sideBar="sideBar"
      :masterDetail="true"
      :keepDetailRowsCount="1"
      :groupDefaultExpanded="1"
      :autoGroupColumnDef="autoGroupColumnDef"
      :suppressAggFuncInHeader="true"
      @row-clicked="onRowClicked"
      :detailCellRendererParams="detailCellRendererParams"
      :getRowHeight="getRowHeight"
      :embedFullWidthRows="false"
      :rowClassRules="rowClassRules"
      :icons="icons"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import EventBus from '@/services/EventBus.js';
import ControllingService from "../services/ControllingService";

import { AgGridVue } from "ag-grid-vue";
import 'ag-grid-enterprise';

import CurrencyCellRenderer from "./CellRenderer/CurrencyCellRenderer";
import DateCellRenderer from "./CellRenderer/DateCellRenderer";
import PercentageCellRenderer from "./CellRenderer/PercentageCellRenderer";
import SurveyInstanceCountCellRenderer from "./CellRenderer/SurveyInstanceCountCellRenderer";
import WeightedBudgetCellRenderer from "./CellRenderer/WeightedBudgetCellRenderer";

import ConfigurationToolPanel from "./ConfigurationToolPanel";
import DetailColumnToolPanel from "./DetailColumnToolPanel";

export default {
  name: "Marketing",
  components: {
    AgGridVue,
    ConfigurationToolPanel,
    currencyCellRenderer: CurrencyCellRenderer,
    dateCellRenderer: DateCellRenderer,
    DetailColumnToolPanel,
    percentageCellRenderer:PercentageCellRenderer,
    surveyInstanceCountCellRenderer: SurveyInstanceCountCellRenderer,
    weightedBudgetCellRenderer: WeightedBudgetCellRenderer,
  },
  data() {
    return {
      autoGroupColumnDef: {
        width: 0,
      },
      columnDefs: null,
      detailCellRendererParams: null,
      getRowHeight: null,
      gridApi: null,
      gridColumnApi: null,
      icons:{
        cog: "<i class='cog-via-css'/>"
      },
      rowClassRules: {
        'first-row': params => params.rowIndex === 0,
        'old-row': params => (params.data?.Year ? params.data.Year : 9999) < new Date().getFullYear(),
      },
      rowData: null,
      selectedRows: null,
      selectedYear: null,
      sideBar: {
        toolPanels: [
          {
            id: 'configuration',
            labelDefault: 'Konfiguration',
            labelKey: 'configuration',
            iconKey: 'cog',
            toolPanel: 'ConfigurationToolPanel',
            toolPanelParams: {
              parentName: this.$options.name,
            },
          },
          {
            id: 'columns',
            labelDefault: 'Spalten - Unterprojekt',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: false,
              suppressColumnSelectAll: false,
              suppressColumnExpandAll: false,
            },
          },
          {
            id: 'detailColumns',
            labelDefault: 'Spalten - Auftrag',
            labelKey: 'detailColumns',
            iconKey: 'columns',
            toolPanel: 'DetailColumnToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filter',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: false,
              suppressFilterSearch: false,
            },
          },
        ],
      },
    };
  },
  created() {
    this.getRowHeight = (params) => {
      if (params.node?.detail && params.data?.Orders) {
        var offset = 80;
        var allDetailRowHeight =
          (params.data.Orders.length <= 10 ? params.data.Orders.length : 10) *
          params.api.getSizesForCurrentTheme().rowHeight;
        var gridSizes = params.api.getSizesForCurrentTheme();
        return (
          allDetailRowHeight +
          ((gridSizes && gridSizes.headerHeight) || 0) +
          offset
        );
      }
    };
  },
  beforeMount() {
    this.selectedYear = new Date().getFullYear();
    this.loadRowData();
    this.columnDefs = [
      { headerName: "AggregationGroup", field: 'AggregationGroup', rowGroup: true, hide: true, suppressColumnsToolPanel: true},
      { headerName: "Kunde", field: "ClientName", sortable: true, filter: true, width:200, resizable: true },
      { headerName: "Projekt", field: "ProjectName", sortable: true, filter: true, width:250, resizable: true },
      { headerName: "Unterprojekt", field: "SubProjectName", sortable: true, filter: true, width:150, resizable: true },
      { headerName: "CL", field: "Consultant", sortable: true, filter: true, resizable: true, width:70 },
      { headerName: "Budget", field: "OrderBudget", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Wahrscheinlichkeit",field: "Probability", hide: true, sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'percentageCellRenderer', 
        aggFunc: params => {
          let total = 0;
          let count = 0;
          params.values.forEach(value => {if(value) {total += value; count++;}});
          return (total / count).toFixed(0);
        }  
      },
      { headerName: "Rückstellungen", field: "Reserve", hide: true, sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' }, 
      { headerName: "Budget abz. Rückstellungen", field: "BudgetMinusReserve", hide: true, sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' }, 
      { headerName: "G&G in €", field: "OverheadCosts", cellRenderer: "currencyCellRenderer", sortable: true, filter: true, width:150, resizable: true, aggFunc: 'sum' },
      { headerName: "G&G in %", field: "OverheadCharge", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'percentageCellRenderer',
        aggFunc: params => {
          let total = 0;
          let count = 0;
          params.values.forEach(value => {if(value) {total += value; count++;}});
          return (total / count).toFixed(0);
        }  
      },
      { headerName: "Vertriebsaufschlag in €", field:"MarketingCosts", cellRenderer: "currencyCellRenderer", sortable: true, filter: true, width:150, resizable: true, aggFunc: 'sum' },
      { headerName: "Vertriebsaufschlag in %", field:"MarketingCharge", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'percentageCellRenderer',
        aggFunc: params => {
          let total = 0;
          let count = 0;
          params.values.forEach(value => {if(value) {total += value; count++;}});
          return (total / count).toFixed(0);
        }  
      },
      { headerName: "Gemeinkosten Ist", field: "AllocationOfOverheadCosts", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Fremdkosten MSM", field: "ExternalCostsMsmTk", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Fremdkosten Agentur", field: "ExternalCostsAgency", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Fremdkosten BOLD", field: "ExternalCostsMdatiqs", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Fremdkosten BOLD 2€ pro Check", field: "SurveyInstancePriceMdatiqs", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Fremdkosten Sonstiges", field: "ExternalCostsOther", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Fremdkosten Ist", field:"ThirdPartyServices", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "DB1", field:"ContributionMargin", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Verfügbares Abteilungsbudget", field: "DepartmentBudget", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Budget Feld", field: "BudgetField", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Restbudget Feld", field: "RemainingBudgetField", sortable: true, filter: true, width:180, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Budget PM", field: "BudgetProjectManagement", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Restbudget PM", field: "RemainingBudgetProjectManagement", sortable: true, filter: true, width:180, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Budget QSS", field: "BudgetQualityAssurance", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Restbudget QSS", field: "RemainingBudgetQualityAssurance", sortable: true, filter: true, width:180, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
      { headerName: "Verbleibendes Budget", field: "RemainingBudget", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
          { headerName: "Etat TK Vergütung gesamt", field: "ExternalCostsMsmTk", sortable: true, filter: true, width:220, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' },
          { headerName: "TK Vergütung verbleibt", field: "ExternalCostsMsmTkLeft", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer', aggFunc: 'sum' }, 
      { headerName: "Erfüllungsrate Checks", field: "SurveyInstanceCount", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'surveyInstanceCountCellRenderer',
        aggFunc: params => {
          let totalSurveyInstanceCount = 0;
          let totalPlannedSurveyInstanceNumber = 0;
          params.values.forEach(sic => {
            if(sic && sic.length) {
              if(sic[0].PlannedSurveyInstanceNumber) totalPlannedSurveyInstanceNumber += sic[0].PlannedSurveyInstanceNumber;
              sic.forEach( e => {
                if(e) {
                  totalSurveyInstanceCount += e.SurveyInstanceCount;
                }
              })
            }
          })
          return [{"SurveyInstanceCount":totalSurveyInstanceCount, "PlannedSurveyInstanceNumber": totalPlannedSurveyInstanceNumber}];
        }  
      },
    ];
  },
  mounted() {
    this.detailCellRendererParams = {
      // level 2 grid options
      detailGridOptions: {
        columnDefs: [
          { headerName: "Auftrag", field: "OrderName", sortable: true, filter: true, width:150, resizable: true },
          { headerName: "Kostenstelle", field: "FullCostCenterCode", sortable: true, filter: true, width: 130, resizable: true },
          { headerName: "Startdatum", field: "StartDate", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'dateCellRenderer' },
          { headerName: "Enddatum", field: "EndDate", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'dateCellRenderer' },
          { headerName: "Budget", field: "Budget", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Rückstellungen", field: "Reserve", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Budget abz. Rückstellungen", field: "BudgetMinusReserve", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' }, 
          { headerName: "G&G in €", field: "OverheadCosts", cellRenderer: "currencyCellRenderer", sortable: true, filter: true, width:150, resizable: true, aggFunc: 'sum' },
          { headerName: "G&G in %", field: "OverheadCharge", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'percentageCellRenderer' },
          { headerName: "Vertriebsaufschlag in €", field:"MarketingCosts", cellRenderer: "currencyCellRenderer", sortable: true, filter: true, width:150, resizable: true, aggFunc: 'sum' },
          { headerName: "Vertriebsaufschlag in %", field:"MarketingCharge", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'percentageCellRenderer' },
          { headerName: "Gemeinkosten Ist", field: "AllocationOfOverheadCosts", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Fremdkosten MSM", field: "ExternalCostsMsmTk", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Fremdkosten Agentur", field: "ExternalCostsAgency", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Fremdkosten BOLD", field: "ExternalCostsMdatiqs", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Fremdkosten BOLD 2€ pro Check", field: "SurveyInstancePriceMdatiqs", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Fremdkosten Sonstiges", field: "ExternalCostsOther", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Fremdkosten Ist", field:"ThirdPartyServices", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "DB1", field:"ContributionMargin", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Verfügbares Abteilungsbudget", field: "DepartmentBudget", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Budget Feld", field: "BudgetField", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Restbudget Feld", field: "RemainingBudgetField", sortable: true, filter: true, width:180, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Budget PM", field: "BudgetProjectManagement", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Restbudget PM", field: "RemainingBudgetProjectManagement", sortable: true, filter: true, width:180, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Budget QSS", field: "BudgetQualityAssurance", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Restbudget QSS", field: "RemainingBudgetQualityAssurance", sortable: true, filter: true, width:180, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Verbleibendes Budget", field: "RemainingBudget", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "Etat TK Vergütung gesamt", field: "ExternalCostsMsmTk", sortable: true, filter: true, width:220, resizable: true, cellRenderer: 'currencyCellRenderer' },
          { headerName: "TK Vergütung verbleibt", field: "ExternalCostsMsmTkLeft", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'currencyCellRenderer' }, 
          { headerName: "Erfüllungsrate Checks", field: "SurveyInstanceCount", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'surveyInstanceCountCellRenderer' },
        ],
        rowSelection: 'single',
        rowClassRules: {
          'old-row': params => (params.data?.Year ? params.data.Year : 9999) < new Date().getFullYear()
        }
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.Orders);
      },
    };
    // listen to event from DetailColumnToolPanel to show/hide level 2 columns
    EventBus.$on('DetailColumnHide', (field, hide) => {
      var def = this.detailCellRendererParams.detailGridOptions.columnDefs.find(def => def.field === field);
      def.hide = hide;
      this.gridApi.onGroupExpandedOrCollapsed();
    });
    EventBus.$on('DetailColumnHideAll', hide => {
      this.detailCellRendererParams.detailGridOptions.columnDefs.forEach(e => e.hide = hide);
      this.gridApi.onGroupExpandedOrCollapsed();
    });
    EventBus.$on(`${this.$options.name}_newConfig`, params => {
      this.selectedYear = params.selectedYear;
      this.loadRowData();
    })
  },
  watch: {
    $route (to, from){
      if (this.$router.currentRoute.path == "/sales") {
        this.loadRowData();
      }
    }
  },
  methods: {
    loadRowData() {
      if(this.gridApi) this.gridApi.showLoadingOverlay();

      ControllingService.getSubprojectAndOrder(null, null, null, null, this.selectedYear)
      .then(res => {
        this.rowData = [];
        if(res.data === '') return;
        this.rowData = res.data;

        // append attribute used in SelectForAggregatedRowCellRenderer for aggregation of selection
        this.rowData.forEach(e => {
          e.isSelectedForAggregation = false;
          e.AggregationGroup = 'Gesamt';
        });
      })
      .finally(() => {
        if(this.gridApi) this.gridApi.hideOverlay();
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onFirstDataRendered() {
      // preselect current year
      // this.gridApi.getFilterInstance("Year").setModel({ values: [new Date().getFullYear(), null] });
      // this.gridApi.onFilterChanged();
    },
    onRowClicked() {
      // prevent for first row, aggregation row
      if(this.gridApi.getFocusedCell().rowIndex === 0) {return;}

      // collapse other rows on selectionchange
      this.gridApi.forEachNode(function (node) {
        if(node.rowIndex === 0) {return};
        if(node.selected === true) {
          node.expanded = !node.expanded;
        } else {
          node.expanded = false;
        }
      });
      this.gridApi.onGroupExpandedOrCollapsed();
    },
  },
}
</script>