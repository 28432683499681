<template>
  <b-card no-body v-draggable class="popup text-dark">
    <b-card-header
      style="height: 25px; user-select: none;"
      class="pl-2 pb-0 pt-0"
      header-tag="div"
    >
      <b-row class="mx-0 px-0">
        <b-col class="mx-0 px-0" cols="11">
          
        </b-col>
        <b-col class="mx-0 px-0" cols="1" style="text-align: right">
          <font-awesome-icon
            class="mx-1 clickable"
            icon="fa-solid fa-xmark"
            @click="close()"
            style="cursor:pointer;"
          />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body style="overflow-y: auto;">
      <b-row style="text-align: center;">
        Zum Importieren von "Fremdkosten Ist" und "Gemeinkosten Ist".
      </b-row>
      <br>
      <b-row style="  display: flex; flex-direction: row; justify-content: center; align-items: center;">
        <span class="mr-2">Wirtschaftsjahr: </span>
        <b-form-select v-model="selectedYear" :options="getYears()" style="width: 180px;"></b-form-select>
      </b-row>
      <br>
      <b-row>
        <b-form-file 
          style="margin:auto; padding: 12px;" 
          accept=".xlsx"
          @change="onInputFileChange"
          placeholder="Keine Datei ausgewählt"
        />
      </b-row>
      <br>
      <b-row>
        <b-button style="width:150px; margin:auto" @click="importOrderData()" :disabled="!file || importStatus != 100">
          <span v-if="importStatus == 100">
            Importieren
          </span>
          <span v-else-if="importStatus == 150">
            Importieren
            <span class="dot-animation">.</span>
          </span>
          <span v-else-if="importStatus == 200">
            Importiert!
            <font-awesome-icon
              icon="fa-solid fa-check"
            />
          </span>
          <span v-else>
            Fehler
          </span>
        </b-button>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { read, utils } from 'xlsx';
import ControllingService from "../services/ControllingService";

export default {
  data() {
    return {
      file: null,
      header: null,
      importData: null,
      importStatus: 100,
      jArray: [],
      selectedYear: null,
    }
  },
  mounted() {
    this.selectedYear = new Date().getFullYear();
  },
  methods: {
    buildJArray() {
      this.jArray = [];
      var indexThirdPartyServices = null;
      var indexAllocationOfOverheadCosts = null;
      for (let i = 0; i < this.importData.length; i++) {
        if(this.importData[i][0] == "Fremdleistungen") indexThirdPartyServices = i;
        if(this.importData[i][0] == "Umlage Gemeinkosten") indexAllocationOfOverheadCosts = i;
      };

      // CostCenterCode
      for (let i = 0; i < this.header.length; i++) {
        var costCenterCode = this.header[i].substring(0, this.header[i].indexOf("\n") - 1);
        this.jArray.push({CostCenterCode: costCenterCode});
      };

      // ThirdPartyServices
      if(indexThirdPartyServices != null) {
        for (let i = 0; i < this.importData[indexThirdPartyServices].length; i++) {
          this.jArray[i].ThirdPartyServices = this.importData[indexThirdPartyServices][i];
        }
      }

      // AllocationOfOverheadCosts
      if(indexAllocationOfOverheadCosts != null) {
        for (let i = 0; i < this.importData[indexAllocationOfOverheadCosts].length; i++) {
          this.jArray[i].AllocationOfOverheadCosts = this.importData[indexAllocationOfOverheadCosts][i];
        }
      }

      // remove first object, becaus it contains just the headers.
      this.jArray.shift();

      // remove rows with nonsense data
      this.jArray.forEach(element => {
        if(isNaN(element.CostCenterCode) || element.CostCenterCode == "") {
          this.jArray.splice(this.jArray.indexOf(element), 1);
        }
      });
    },
    close() {
      if(this.importStatus == 200) {
        this.$emit('close-reload');
      } else {
        this.$emit('close');
      }
    },
    getYears() {
      const years = [];
      const lastYear = new Date().getFullYear() + 1;
      const firstYear = lastYear - 5;
      for (let year = lastYear; year >= firstYear; year--) years.push(year);
      return years;
    },
    importOrderData() {
      this.importStatus = 150;
      ControllingService.importOrderData(JSON.stringify({ jArray: this.jArray, Year: this.selectedYear}))
      .then(res => {
        if(res.status == 200) {
          this.importStatus = 200;
        } 
      })
      .catch(err => {
        this.importStatus = 300;
        console.error(err);
      });
    },
    onInputFileChange(e) {
      if(e.target.files[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        this.importStatus = 300;
        return;
      } else {
        this.importStatus = 100;
        this.file = e.target.files[0];
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        this.importData = utils.sheet_to_json(ws, { header: 1, defval: null});
        this.header = this.importData.shift();
        this.buildJArray();
      };
      reader.readAsBinaryString(this.file);
    },
  },
}
</script>

<style scoped>
  .popup {
    width: 400px;
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  }
  .dot-animation {
    animation: dot-animation 1s infinite linear;
    animation-delay: 0.5s;
    font-weight: bolder;
  }
  .dot-animation::before {
    content: ".";
    animation: dot-animation 1s infinite linear;
    animation-delay: 0.25s;
  }
  .dot-animation::after {
    content: ".";
    animation: dot-animation 1s infinite linear;
    animation-delay: 0.75s;
  }
  @keyframes dot-animation {
    0% {
      color: rgba(255, 255, 255, 0);
    }
    50% {
      color: rgba(255, 255, 255, 0.75);
    }
    100% {
      color: rgb(255, 255, 255);
    }
  }
</style>