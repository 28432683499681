<template>
  <div id="home">
    <Header></Header>
    <div id="app-content">
      <keep-alive>
        <router-view :key="$route.fullPath"></router-view> 
      </keep-alive>
    </div>
    <GenericPopup v-if="SurveyInstanceCountPopup.show === true" @close="closeSurveyInstanceCountPopup()" width="20vw">
      <h4 v-if="SurveyInstanceCountPopup.data.OrderName">{{ SurveyInstanceCountPopup.data.OrderName }}</h4>
      <h4 v-else-if="SurveyInstanceCountPopup.data.SubProjectName">{{ SurveyInstanceCountPopup.data.SubProjectName }}</h4>
      <table class="popup-table">
        <tr class="popup-tr">
          <th class="popup-th">Status</th>
          <th class="popup-th">Checkanzahl</th>
        </tr>
        <tr class="popup-tr" v-for="(e, index) in SurveyInstanceCountPopup.data.SurveyInstanceCount" :key="index">
          <template v-if="e.SurveyInstanceCount">
            <td class="popup-td">{{ e.SurveyInstanceStatusId }}</td>
            <td class="popup-td">{{ e.SurveyInstanceCount }}</td>
          </template>
        </tr>
      </table>
    </GenericPopup>
    <Footer></Footer>
  </div>
</template>

<script>
import EventBus from '@/services/EventBus.js';

import Footer from './Footer.vue';
import GenericPopup from './GenericPopup.vue';
import Header from './Header.vue';

export default {
  name: 'Home',
  components: {
    Header, Footer, GenericPopup
  },
  data() {
    return {
      SurveyInstanceCountPopup: {
        data: null,
        show: false,
      }
    }
  },
  created() {
    EventBus.$on('showSurveyInstanceCountPopup', data => {
      if(this.SurveyInstanceCountPopup.data === data) {
        this.closeSurveyInstanceCountPopup();
      } else {
        this.SurveyInstanceCountPopup.show = true;
        this.SurveyInstanceCountPopup.data = data;
      }
    });
  },
  watch: {
    $route (to, from){
      this.closeSurveyInstanceCountPopup();
    }
  },
  methods: {
    closeSurveyInstanceCountPopup() {
      this.SurveyInstanceCountPopup.show = false;
      this.SurveyInstanceCountPopup.data = null;
    }
  },
}
</script>

<style>
#home {
  height: 100vh;
}
#app-content {
  margin: 1rem;
  overflow-y: auto;
  margin-bottom: 40px;
  /* height: 90%; */
}
.popup-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.popup-td, .popup-th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.popup-tr:nth-child(even) {
  background-color: rgb(252, 252, 252);
}

/* row styles */
.first-row {
  font-weight: bold;
  background-color: #52688d31 !important;
}
.old-row {
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(240, 240, 240, 0.3) !important;
}
</style>
