import Oidc from 'oidc-client';
import axios from 'axios';
import UsersService from '@/services/UsersService';
import { changeLocalization } from '@/i18n';
import VueCookies from 'vue-cookies';

export default new class AuthenticationService {

    userManager
    isAuthenticated = false
    isAuthenticatedExternal = false
    userProfile
    localUserProfile = null
    redirectUri
  
    getUser() {
      return this.UserManager.getUser().then(user => {
        if (user) {
          this.userProfile = user.profile;
          axios.defaults.headers.Authorization = "Bearer " + user.access_token;

          // Get user profile data from backend
          return UsersService.getUserProfile().then(response => {
            if (response.data) {
              this.isAuthenticated = true;
              this.localUserProfile = response.data;
  
              return;
                //changeLocalization(this.localUserProfile.LocalizationId);
            } else {
              return this.UserManager.removeUser().then(() => {
                return Promise.reject('unauthorized');
              });
            }
          }).catch((e) => {
            // If backend returns 401, the access token is probably expired --> sign in again
            if (e && e.response && e.response.status == 401)
              return this.UserManager.signinRedirect();
            else
              return Promise.reject(e);
          });
        }
        else {
          this.isAuthenticated = false;
          this.userProfile = null;
        }
      });
    }
  
    login(login_hint) {
      return this.getUser().then(() => {
        if (!this.isAuthenticated) {
          if (login_hint)
            return this.UserManager.signinRedirect({ login_hint: login_hint, state: window.location.pathname + window.location.search });
          else
            return this.UserManager.signinRedirect({ state: window.location.pathname + window.location.search });
        }
      });
    }
  
    callback() {
      return this.UserManager.signinRedirectCallback().then((user) => {
        this.redirectUri = user.state;
        return this.getUser();
      }).catch((e) => {
        this.isAuthenticated = false;
        this.userProfile = null;
        console.error(e);
        return Promise.reject(e);
      });
    }
  
    logout() {
      if (!this.isExternalUser)
        return this.UserManager.signoutRedirect();
      else {
        this.isAuthenticated = false;
        this.isAuthenticatedExternal = false;
        VueCookies.remove("ExternalUser");
        return Promise.resolve();
      }
    }

    hasPermission(permission) {
      return this.localUserProfile.Permissions.includes(permission) ? true : false;
    }
  
    get UserManager() {
      if (!this.userManager) {
        var config = {
          authority: process.env.VUE_APP_AUTH_SERVERURL,
          client_id: 'loopControllingFrontend',
          redirect_uri: `${window.location.origin}${process.env.BASE_URL}callback`,
          response_type: 'id_token token',
          scope: 'openid profile email loopControllingBackend',
          post_logout_redirect_uri: window.location.origin,
        };
  
        this.userManager = new Oidc.UserManager(config);
      }
  
      return this.userManager;
    }
  
    get isMSMUser() {
      return this.localUserProfile.UserType === 'M';
    }
  
    get isExternalUser() {
      return this.localUserProfile.UserType === 'E';
    }
  }