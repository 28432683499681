<template>
  <div v-if="this.params.data" style="width: fit-content;">
    <b-form-input
      type="number"
      :step="0.01"
      :min="0"
    ></b-form-input>
  </div>
</template>

<script>
import EventBus from '../../services/EventBus';
export default {
  methods: {
    // cancelInvoice() {
    //   EventBus.$emit("cancelInvoice", this.params.data);
    // }
    getValue() {
      return this.params.data;
    }
  }
}
</script>