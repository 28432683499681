<template>
  <div v-if="this.params.value">
    <div v-if="CountAll">
      <a class="popup-trigger" @click="showPopup()">
        {{CountAll}}/{{this.params.value[0].PlannedSurveyInstanceNumber}}
      </a>
    </div>
    <div v-else-if="this.params.value[0].PlannedSurveyInstanceNumber">
      0/{{this.params.value[0].PlannedSurveyInstanceNumber}}
    </div>
  </div>
</template>

<script>
import EventBus from '../../services/EventBus';

export default {
  data() {
    return {
      CountAll: null,
    }
  },
  mounted() {
    this.params.value?.forEach(e => {
      if(e?.SurveyInstanceCount) this.CountAll += e?.SurveyInstanceCount;
    });
  },
  methods: {
    showPopup() {
      if(this.params.data) {
        EventBus.$emit("showSurveyInstanceCountPopup", this.params.data);
      } 
    }
  },
}
</script>

<style scoped>
.popup-trigger {
  text-decoration: underline;
  color: rgb(24, 29, 31);
}
.popup-trigger:hover {
  cursor: pointer;
  font-weight: bold;
}
</style>