import axios from 'axios';

export default {
  baseURL: process.env.BASE_URL + 'api/controlling',

  getClientWithProjectAndSubProject() {
    return axios.get(
      'client-project-subproject',
      { 
        baseURL: this.baseURL
      })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  getSubprojectAndOrder(projectIds, readArchive, invoiceDateStart, invoiceDateEnd, year) {
    return axios.get(
      'subproject-order',
      { 
        baseURL: this.baseURL,
        params: {
          "project-ids": projectIds,
          "read-archive": readArchive,
          "invoice-date-start": invoiceDateStart,
          "invoice-date-end": invoiceDateEnd,
          "year": year
        },
      },
      )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  setSubProject(subProject) {
    return axios.post(
      'set-sub-project', 
      subProject,
      {
        baseURL: this.baseURL
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  getSubprojectHistory(subprojectId) {
    return axios.get(
      'get-subproject-history',
      { 
        baseURL: this.baseURL,
        params: {
        "subproject-id": subprojectId
        },
      },
      )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  setSubprojectInactive(subprojectId) {
    return axios.get(
      'set-subproject-inactive',
      { 
        baseURL: this.baseURL,
        params: {
        "subproject-id": subprojectId
        },
      },
      )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  setSubprojectIsArchived(subprojectId, isArchived) {
    return axios.get(
      'set-subproject-is-archived',
      { 
        baseURL: this.baseURL,
        params: {
          "subproject-id": subprojectId,
          "is-archived": isArchived
        },
      },
      )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  setOrder(order) {
    return axios.post(
      'set-order', 
      order,
      {
        baseURL: this.baseURL
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  getOrderHistory(orderId) {
    return axios.get(
      'get-order-history',
      { 
        baseURL: this.baseURL,
        params: {
        "order-id": orderId
        },
      },
      )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  setOrderInactive(orderId) {
    return axios.get(
      'set-order-inactive',
      { 
        baseURL: this.baseURL,
        params: {
        "order-id": orderId
        },
      },
      )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  setInvoice(invoice) {
    return axios.post(
      'set-invoice', 
      invoice,
      {
        baseURL: this.baseURL
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  cancelInvoice(invoiceId) {
    return axios.get(
      'cancel-invoice',
      { 
        baseURL: this.baseURL,
        params: {
        "invoice-id": invoiceId
        },
      },
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  setInvoiceReminderLevel(invoiceId, reminderLevel) {
    return axios.get(
      'set-invoice-reminder-level',
      { 
        baseURL: this.baseURL,
        params: {
          "invoice-id": invoiceId,
          "reminder-level": reminderLevel
        },
      },
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  setClientCode(clientCode) {
    return axios.post(
      'set-clientcode', 
      clientCode,
      {
        baseURL: this.baseURL
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  getCountryList() {
    return axios.get(
      'country-list',
      { 
        baseURL: this.baseURL
      },
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  getOrganization() {
    return axios.get(
      'get-organization',
      { 
        baseURL: this.baseURL
      },
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  setOrganization(organization) {
    return axios.post(
      'set-organization', 
      organization,
      {
        baseURL: this.baseURL
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  getVersion() {
    return axios.get(
      'get-version',
      { 
        baseURL: this.baseURL
      },
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  importOrderData(orderData) {
    return axios.post(
      'import-order-data', 
      orderData,
      {
        baseURL: this.baseURL,
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  setOrderYearCorrection(data) {
    return axios.get(
      'set-order-year-correction',
      { 
        baseURL: this.baseURL,
        params: {
          "order-id": data.OrderId,
          "year": data.Year,
          "correction": data.Correction,
          "correction-note": data.CorrectionNote
        },
      },
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  getOrderManagement() {
    return axios.get(
      'get-order-management',
      { 
        baseURL: this.baseURL,
      },
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  getOrderNameAvailable(data) {
    return axios.get(
      'get-order-name-available',
      { 
        baseURL: this.baseURL,
        params: {
          "subproject-id": data.SubProjectId,
          "order-id": data.OrderId,
          "order-name": data.OrderName
        },
      },
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },

  getSubProjectNameAvailable(data) {
    return axios.get(
      'get-subproject-name-available',
      { 
        baseURL: this.baseURL,
        params: {
          "project-id": data.ProjectId,
          "subproject-id": data.SubProjectId,
          "subproject-name": data.SubProjectName
        },
      },
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return Promise.reject(error);
    });
  },
}