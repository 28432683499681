<template>
  <div v-if="this.params.data" style="width: fit-content;">
    <font-awesome-icon
      v-if="!this.params.value"
      class="mx-1"
      icon="fa-regular fa-square"
      @click="payInvoice"
      style="cursor:pointer;"
    />
    <font-awesome-icon
      v-else
      class="mx-1"
      @click="payInvoice"
      icon="fa-regular fa-square-check"
      style="cursor:pointer;"
    />
  </div>
</template>

<script>
import EventBus from '../../services/EventBus';
export default {
  methods: {
    payInvoice() {
      EventBus.$emit("payInvoice", this.params.data);
    }
  }
}
</script>